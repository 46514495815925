/* eslint-disable 	@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call, @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return,  @typescript-eslint/no-floating-promises, @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react';
import _ from 'utils/lodash';
import { inject, observer } from 'mobx-react';
import { Checkbox } from '../../../primitives/checkbox';
import Spinner from 'components/common/next/spinner';
import type { TargetGroup, SelectionEmails } from 'components/next/types';
import { toJS } from 'mobx';
import DimensionsStore, { GroupedSelectionEmails } from 'stores/next/dimensions';
import DrawerContent from '../../../wrappers/drawerContent';
import AlertStore from 'stores/next/alerts';
import './targetGroupForm.scss';
import Button from '../../../../../../common/next/form/button';
import TemplateStore from 'stores/next-retailer/templateStore';
import DeliveryStore from 'stores/next-retailer/deliveryStore';
import InfoPopover from '../../../common/infoPopover';
import ContentStore from 'stores/next-retailer/contentStore';
import AppStore from 'stores/next/app';
import { ReactComponent as IconSwitch } from '@kesko/icons/action/icon-switch.svg';
import { ReactComponent as ChevronUp } from '@kesko/icons/nav/icon-chevron-up.svg';
import { ReactComponent as ChevronDown } from '@kesko/icons/nav/icon-chevron-down.svg';
import InputText from 'components/common/next/form/inputText';
import { isValidEmail, useDebouncedEffect } from 'utils/helpers';
import ConceptStore from 'stores/next-retailer/conceptStore';
import { getPrintRecipientCount } from '../../../../utils/helpers';
import { BusinessType, Chain, ConceptType } from 'enums/common';
import { useNavigate, useParams } from 'react-router-dom';
import { getLink } from 'components/retailer/next/routes';
import InputCsv from 'components/common/next/form/inputCsv';
import { getConceptDefaults } from 'components/next/utils';
import { get } from 'lodash';

interface InjectedProps {
  conceptStore?: ConceptStore;
  templateStore?: TemplateStore;
  deliveryStore?: DeliveryStore;
  dimensionsStore?: DimensionsStore;
  contentStore?: ContentStore;
  alertStore?: AlertStore;
  appStore?: AppStore;
}

const TargetGroupForm = ({
  conceptStore,
  templateStore,
  deliveryStore,
  dimensionsStore,
  contentStore,
  alertStore,
  appStore,
}: InjectedProps) => {
  const navigate = useNavigate();
  const { templateId: templateIdParam, deliveryId: deliveryIdParam, mobileOffersId: mobileOffersIdParam } = useParams();
  const [selectedEmails, setSelectedEmails] = useState<GroupedSelectionEmails[]>([]);
  const [selectedBannedEmails, setSelectedBannedEmails] = useState<GroupedSelectionEmails[]>([]);
  const [emailInput, setEmailInput] = useState('');
  const [emailSearch, setEmailSearch] = useState('');
  const [selectedAdditionalEmails, setSelectedAdditionalEmails] = useState<string[]>([]);
  const [expandedRows, setExpandedRows] = useState<SelectionEmails[]>([]);
  // These are purely for the debounce effect to work, the value does not matter.
  const [updateSelectionCountsTrigger, triggerUpdateSelectionCounts] = useState(false);
  const [updateSelectionAreasTrigger, triggerUpdateSelectionAreas] = useState(false);
  const [updateSelectionEmailsTrigger, triggerUpdateSelectionEmails] = useState(false);

  const getTemplate = () => {
    if (mobileOffersIdParam) {
      return _.find(templateStore.templates, { id: '' });
    }
    return _.find(templateStore.templates, { id: templateIdParam });
  };

  const getConcept = () => {
    if (mobileOffersIdParam) {
      return getConceptDefaults([]);
    }
    return conceptStore.getConcept(getTemplate().concept);
  };

  const getDelivery = () => {
    return deliveryStore.current;
  };

  const getDimensions = () => {
    return dimensionsStore.dimensions;
  };

  const getB2bDimensions = () => {
    return dimensionsStore.b2bDimensions;
  };

  const getB2bEmailRecipients = () => {
    return dimensionsStore.b2bEmailRecipients;
  };

  const getGroupedB2bEmailRecipients = () => {
    return dimensionsStore.groupedB2bEmailRecipients;
  };

  const getAvailableDimensions = () => {
    const { targetGroupOpts } = getTemplate();
    return _.chain(targetGroupOpts.dimensions || [])
      .filter((d) => !_.isEmpty(d.options))
      .map('name')
      .map(getDimension)
      .filter()
      .value();
  };

  const getAvailableB2BDimensions = () => {
    const { targetGroupOpts } = getTemplate();
    return _.chain(targetGroupOpts.dimensions || [])
      .filter((d) => !_.isEmpty(d.options))
      .map('name')
      .map(getB2BDimension)
      .filter()
      .value();
  };

  const getMainDimension = () => {
    return _.find(getAvailableDimensions(), { name: 'segmentti' });
  };

  const getSeasonalDimension = () => {
    return _.find(getAvailableDimensions(), { name: 'sesonki' });
  };

  const getAdditionalDimensions = () => {
    return getAvailableDimensions().filter((d) => !['segmentti', 'sesonki'].includes(d.name));
  };

  const getAdditionalB2BDimensions = () => {
    return getAvailableB2BDimensions().filter((d) => !['segmentti', 'sesonki'].includes(d.name));
  };

  const isReady = () => {
    return (
      getTemplate() &&
      getDelivery() &&
      getDelivery().maxSelectionAreasLoading !== undefined &&
      getDimensions() &&
      getB2bDimensions()
    );
  };

  const isNew = () => {
    return mobileOffersIdParam ? mobileOffersIdParam === 'new' : deliveryIdParam === 'new';
  };

  const isEditable = () => {
    const { targetGroupOpts } = getTemplate();
    if (targetGroupOpts.useAreas) {
      return true;
    }
    const options = toJS(targetGroupOpts.dimensions).flatMap(({ options }) => options);
    const locked = toJS(targetGroupOpts.dimensions).flatMap(({ locked }) => locked);
    const editableOptions = options.filter((option) => !locked.includes(option));
    if (editableOptions.length > 0) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const delivery = getDelivery();
    if (deliveryIdParam && (!delivery || (!isNew() && delivery.id !== deliveryIdParam))) {
      navigate(getLink('editDelivery', deliveryIdParam, templateIdParam), { state: { disableNotification: true } });
    }
    if (mobileOffersIdParam && (!delivery || (!isNew() && delivery.id !== mobileOffersIdParam))) {
      navigate(getLink('editMobileOffers', mobileOffersIdParam), { state: { disableNotification: true } });
    }
    const init = async () => {
      checkInitialCounts();
      const concept = await getConcept();
      conceptStore.setCurrent(concept);
      if (!getB2bEmailRecipients() && delivery.targetGroup.targetGroupType === BusinessType.B2b) {
        await dimensionsStore.getSelectionEmails(delivery.targetGroup);
      }
    };
    init();
  }, []);

  const checkInitialCounts = () => {
    if (getDelivery() && getDelivery().maxSelectionAreasLoading === undefined) {
      updateSelectionCounts();
      updateSelectionAreas();
    }
  };

  const updateSelectionCounts = () => {
    deliveryStore.getMaxSelectionCountsForDelivery(getDelivery());
  };

  const updateSelectionAreas = () => {
    deliveryStore.getMaxSelectionAreasForDelivery(getDelivery());
  };

  const updateSelectionEmails = async () => {
    // only update selection emails if the delivery is B2B
    if (getDelivery().targetGroup.targetGroupType === BusinessType.B2b)
      await dimensionsStore.getSelectionEmails(getDelivery().targetGroup);
  };

  useDebouncedEffect(updateSelectionCounts, [updateSelectionCountsTrigger], 2000);
  useDebouncedEffect(updateSelectionAreas, [updateSelectionAreasTrigger], 2000);
  useDebouncedEffect(updateSelectionEmails, [updateSelectionEmailsTrigger], 2000);
  const trigger = (value: boolean) => !value;

  const dimensionChecked = (name: string, value: string) => {
    const { targetGroup } = getDelivery();
    const dimensions = targetGroup.dimensions || [];
    const dimension = _.find(dimensions, { name });
    return dimension && _.includes(dimension.selection, value);
  };

  const dimensionLocked = (name: string, value: string) => {
    const { targetGroupOpts } = getTemplate();
    const dimensions = targetGroupOpts.dimensions || [];
    const dimension: TargetGroup = _.find(dimensions, { name });
    return dimension && _.includes(dimension.locked, value);
  };

  const dimensionAvailable = (name: string, value: string) => {
    const { targetGroupOpts } = getTemplate();
    const dimensions = targetGroupOpts.dimensions || [];
    const dimension: TargetGroup = _.find(dimensions, { name });
    return dimension && _.includes(dimension.options, value);
  };

  const getDimension = (name: string) => {
    return _.find(getDimensions(), { name });
  };

  const getB2BDimension = (name: string) => {
    return _.find(getB2bDimensions(), { name });
  };

  const getBanFilteredB2bEmailRecipients = () => {
    const bannedB2bEmailRecipients = getBannedB2bEmailRecipients();
    const banFilteredB2bEmailRecipients = _.filter(getGroupedB2bEmailRecipients(), (recipient) => {
      return !_.find(bannedB2bEmailRecipients, { email: recipient.email });
    });
    return banFilteredB2bEmailRecipients;
  };

  const getSearchFilteredB2bEmailRecipients = () => {
    const banFilteredB2bRecipients = getBanFilteredB2bEmailRecipients();
    const searchFilteredB2bRecipients = _.filter(banFilteredB2bRecipients).filter((recipient) => {
      return recipient.email.toLowerCase().includes(emailSearch.toLowerCase());
    });
    return searchFilteredB2bRecipients;
  };

  const getBannedB2bEmailRecipients = () => {
    const bannedEmails = getDelivery()?.emailRecipients?.bannedEmails || [];
    const bannedB2bEmailRecipients = _.map(bannedEmails, (email) => {
      let recipient = _.find(getGroupedB2bEmailRecipients(), { email });
      if (!recipient) {
        recipient = { email, company_name: 'Ei tiedossa', cust_contact_id: '', group: [], ytunnus: 'Ei tiedossa' };
      }
      return recipient;
    });
    return bannedB2bEmailRecipients;
  };

  const getSearchFilteredBannedB2bEmailRecipients = () => {
    const filteredBannedB2bEmails = getBannedB2bEmailRecipients();
    const searchFilteredBannedEmails = _.filter(filteredBannedB2bEmails, (bannedEmail) => {
      return bannedEmail.email.toLowerCase().includes(emailSearch.toLowerCase());
    });
    return searchFilteredBannedEmails;
  };

  const toggleDimension = (name: string, value: string) => {
    return (e: React.SyntheticEvent) => {
      const d = toJS(getDelivery());
      let dimension = _.find(d.targetGroup.dimensions, { name });
      if (!dimension) {
        dimension = { name, selection: [] };
        d.targetGroup.dimensions.push(dimension);
      }
      const checked = dimensionChecked(name, value);
      if (!checked) {
        dimension.selection = [...dimension.selection, value];
      } else {
        dimension.selection = _.filter(dimension.selection, (v: string) => v !== value);
      }
      deliveryStore.setCurrent(d);

      // update selection counts, areas, emails
      triggerUpdateSelectionAreas(trigger);
      triggerUpdateSelectionCounts(trigger);
      triggerUpdateSelectionEmails(trigger);
    };
  };

  const renderDimensionSelect = (dimension) => {
    const options = _.orderBy(dimension.options, 'order').filter(({ name }) =>
      dimensionAvailable(dimension.name, name),
    );
    return (
      <div className="checkboxes">
        {options.map(({ title, name }) => (
          <Checkbox
            key={name}
            id={`dimension-${name}-${title}`}
            label={title}
            checked={dimensionChecked(dimension.name, name)}
            onChange={toggleDimension(dimension.name, name)}
            disabled={dimensionLocked(dimension.name, name)}
          />
        ))}
      </div>
    );
  };

  const renderAdditionalDimensions = () => {
    const dimensionName = appStore.chain === Chain.kRauta ? 'Asiakasryhmät' : 'Kohderyhmän tarkennukset';
    const slugName = appStore.chain === Chain.kRauta ? 'info-asiakasryhmat' : 'info-tarkennukset';

    return (
      <div className="additional-dimensions">
        <div className="section-header">
          <h3>{dimensionName}</h3>
          {infoButton({ slug: slugName })}
        </div>
        {_.sortBy(getAdditionalDimensions(), 'order').map((dimension) => {
          return (
            <div key={dimension.name} className="dimension-section">
              <header>
                <h4 className="dimension-title">{dimension.title}</h4>
                {infoButton({ slug: `info-dimension-${dimension.name}` })}
              </header>
              {renderDimensionSelect(dimension)}
            </div>
          );
        })}
      </div>
    );
  };

  const moveSelectedEmails = () => {
    if (!selectedEmails && !selectedBannedEmails) {
      return;
    }

    const d = toJS(getDelivery());

    const bannedEmailStrings = selectedBannedEmails.map((bannedEmail) => bannedEmail.email);
    const selectedEmailStrings = selectedEmails.map((selectedEmail) => selectedEmail.email);

    // Only push the selected emails to the delivery if they are not already banned
    const selectedEmailsToBan = selectedEmailStrings.filter((email) => !bannedEmailStrings.includes(email));
    const selectedBannedEmailsToUnban = bannedEmailStrings.filter((email) => !selectedEmailStrings.includes(email));

    if (!d.emailRecipients) {
      d.emailRecipients = { bannedEmails: [], additionalEmails: [] };
    }

    // Add the selected emails to the delivery banned emails, and remove selectedBannedEmails from it
    d.emailRecipients.bannedEmails = [...d.emailRecipients.bannedEmails, ...selectedEmailsToBan];
    d.emailRecipients.bannedEmails = _.difference(d.emailRecipients.bannedEmails, selectedBannedEmailsToUnban);

    deliveryStore.setCurrent(d);

    setSelectedEmails([]);
    setSelectedBannedEmails([]);
  };

  const deleteSelectedEmails = () => {
    if (!selectedAdditionalEmails) {
      return;
    }
    const d = toJS(getDelivery());
    d.emailRecipients.additionalEmails = _.difference(d.emailRecipients.additionalEmails, selectedAdditionalEmails);
    deliveryStore.setCurrent(d);
    setSelectedAdditionalEmails([]);
  };

  const isExpanded = (row: any) => {
    return expandedRows.includes(row);
  };

  const handleEmailSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmailSearch(value);
  };

  const handleFileContent = (textContent: string) => {
    const possibleEmailsSet = new Set<string>();
    textContent
      .split(/(\r\n|\r|\n)/) // Split on line endings
      .flatMap((line) => {
        return line.split(/[;,\t| ]/); // Split on possible separators
      })
      .map((email) => {
        return email
          .replace(/"(.*?)"/g, '$1') // Remove surrounding double quotes
          .replace(/'(.*?)'/g, '$1') // Remove surrounding single quotes
          .replace(/<(.*?)>/g, '$1'); // Remove surrounding angle brackets
      })
      .forEach((entry) => {
        possibleEmailsSet.add(entry);
      });
    addAdditionalEmail(Array.from(possibleEmailsSet));
  };

  const renderB2BDimensions = () => {
    const dimensionName = 'Asiakasryhmät';
    const slugName = 'info-asiakasryhmat';
    const additionalEmails = getDelivery()?.emailRecipients?.additionalEmails || [];
    const allAdditionalEmailsChecked =
      selectedAdditionalEmails.length && selectedAdditionalEmails.length === additionalEmails.length;
    const searchFilteredEmails = getSearchFilteredB2bEmailRecipients();
    const searchFilteredBannedEmails = getSearchFilteredBannedB2bEmailRecipients();
    const allBannedEmailsChecked =
      selectedBannedEmails.length && selectedBannedEmails.length === searchFilteredBannedEmails.length;

    return (
      <div className="additional-dimensions">
        <div className="section-header">
          <h3>{dimensionName}</h3>
          {infoButton({ slug: slugName })}
        </div>
        {_.sortBy(getAdditionalB2BDimensions(), 'order').map((dimension) => {
          return (
            <div key={dimension.name} className="dimension-section">
              <header>
                <h4 className="dimension-title">{dimension.title}</h4>
                {infoButton({ slug: `info-dimension-${dimension.name}` })}
              </header>
              {renderDimensionSelect(dimension)}
            </div>
          );
        })}
        <div className="dimension-section">
          <header>
            <h4 className="dimension-title">Sähköpostiyhteystiedot</h4>
            {infoButton({ slug: `info-dimension-email-recipients` })}
          </header>
          <InputText
            name="emailSearch"
            value={emailSearch}
            placeholder={'Etsi vastaanottajaa'}
            onChange={handleEmailSearch}
          />
          <div className="email-table-container">
            <div className="email-table">
              <strong>Vastaanottajat</strong>
              <table className="styled">
                <tbody className="email-table-body">
                  {searchFilteredEmails?.map((recipient, i) => (
                    <React.Fragment key={`email-recipient-${recipient.email}-${recipient.ytunnus}-${i}`}>
                      <tr key={`${recipient.email}-${recipient.ytunnus}-${i}`}>
                        <td>
                          <Checkbox
                            id={`email-recipient-${recipient.email}-${recipient.ytunnus}`}
                            checked={_.includes(selectedEmails, recipient)}
                            onChange={toggleSelectionEmail(recipient)}
                          />
                        </td>
                        <td style={{ background: recipient.group && recipient.group.length > 0 ? 'none' : 'none' }}>
                          {recipient.email}
                        </td>
                        <td>
                          {recipient.company_name}
                          {recipient.group && recipient.group.length > 0 && (
                            <span>
                              <span title="Muut yritykset saman sähköpostin alla" className="grouped-email-pill">
                                {recipient.group.length}
                              </span>
                              <button className="expand-email-group" onClick={() => toggleExpandedRow(recipient)}>
                                {isExpanded(recipient) ? <ChevronUp /> : <ChevronDown />}
                              </button>
                            </span>
                          )}
                        </td>
                        <td>{recipient.ytunnus}</td>
                      </tr>
                      {isExpanded(recipient) &&
                        recipient.group.map((groupEmail) => (
                          <tr className="email-group-row" key={`${groupEmail.email}-${groupEmail.ytunnus}`}>
                            <td></td>
                            <td></td>
                            <td>{groupEmail.company_name}</td>
                            <td>{groupEmail.ytunnus}</td>
                          </tr>
                        ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <button className="move-emails-button" onClick={moveSelectedEmails}>
              <IconSwitch title="Siirrä valitut vastaanottajat" />
            </button>

            <div className="email-table">
              <table className="styled">
                <thead>
                  <tr>
                    <th>
                      <Checkbox
                        id="select-all-banned"
                        checked={allBannedEmailsChecked}
                        onChange={(e) => selectAllEmails(!allBannedEmailsChecked, e)}
                      />
                      <strong>Vastaanottajalistalta poistetut</strong>
                    </th>
                  </tr>
                </thead>
                <tbody className="email-table-body">
                  {searchFilteredBannedEmails?.map((recipient, i) => (
                    <React.Fragment key={`email-recipient-${recipient.email}-${recipient.ytunnus}-${i}`}>
                      <tr key={`${recipient.email}-${recipient.ytunnus}-${i}`}>
                        <td>
                          <Checkbox
                            id={`email-recipient-${recipient.email}-${recipient.ytunnus}`}
                            checked={!!_.find(selectedBannedEmails, { email: recipient.email })}
                            onChange={toggleSelectionBannedEmail(recipient)}
                          />
                        </td>
                        <td>{recipient.email}</td>
                        <td>
                          {recipient.company_name}{' '}
                          {recipient.group && recipient.group.length > 0 && (
                            <>
                              <span title="Muut yritykset saman sähköpostin alla" className="grouped-email-pill">
                                {recipient.group.length}
                              </span>
                              <button className="expand-email-group" onClick={() => toggleExpandedRow(recipient)}>
                                {isExpanded(recipient) ? <ChevronUp /> : <ChevronDown />}
                              </button>
                            </>
                          )}
                        </td>
                        <td>{recipient.ytunnus}</td>
                      </tr>
                      {isExpanded(recipient) &&
                        recipient.group.map((groupEmail) => (
                          <tr className="email-group-row" key={`${groupEmail.email}-${groupEmail.ytunnus}`}>
                            <td></td>
                            <td></td>
                            <td>{groupEmail.company_name}</td>
                            <td>{groupEmail.ytunnus}</td>
                          </tr>
                        ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="prospect-header">{infoButton({ slug: `info-b2b-prospects` })}</div>
          <div className="email-input-container">
            <InputText
              name="emailInput"
              value={emailInput}
              placeholder={'Lisää sähköposti vastaanottajalistaan'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmailInput(e.target.value)}
            />
            <Button disabled={!isValidEmail(emailInput)} onClick={handleAdditionalEmailEvent}>
              Lisää
            </Button>
          </div>
          <InputCsv
            handleFileContent={handleFileContent}
            dragDropText="Raaha ja pudota sähköpostitiedosto tähän, tai klikkaa valitaksesi"
            dragActiveText="Pudota sähköpostitiedosto tähän ..."
            iconTitle="Lisää sähköpostitiedosto"
          />
          <div className="email-table-container">
            <table className="styled">
              <thead>
                <tr>
                  <th>
                    <Checkbox
                      id="select-all-additional"
                      checked={allAdditionalEmailsChecked}
                      onChange={(e) => selectAllEmails(!allAdditionalEmailsChecked, e)}
                      disabled={!additionalEmails?.length}
                    />
                    <strong>Omat sähköpostit ({additionalEmails.length})</strong>
                  </th>
                </tr>
              </thead>
              <tbody className="email-table-body additional">
                {additionalEmails.map((email) => (
                  <tr key={email}>
                    <td>
                      <Checkbox
                        id={`email-recipient-${email}`}
                        checked={_.includes(selectedAdditionalEmails, email)}
                        onChange={toggleSelectionAdditionalEmail(email)}
                      />
                    </td>
                    <td className="additional-email">{email}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            style={{ display: selectedAdditionalEmails.length > 0 && additionalEmails.length > 0 ? 'flex' : 'none' }}
            className="additional-email-actions"
          >
            {selectedAdditionalEmails.length} / {additionalEmails.length} sähköpostia valittu
            <div className="actions">
              <Button color="bordered" onClick={deleteSelectedEmails}>
                Poista valitut
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const toggleSelectionAdditionalEmail = (email: string) => () => {
    if (_.includes(selectedAdditionalEmails, email)) {
      setSelectedAdditionalEmails(_.without(selectedAdditionalEmails, email));
    } else {
      setSelectedAdditionalEmails([...selectedAdditionalEmails, email]);
    }
  };

  const selectAllEmails = (selectAll: boolean, e: React.SyntheticEvent) => {
    const target = e.currentTarget.id;
    switch (target) {
      case 'select-all-additional': {
        if (!selectAll) {
          setSelectedAdditionalEmails([]);
        } else {
          setSelectedAdditionalEmails(getDelivery().emailRecipients.additionalEmails);
        }
        break;
      }
      case 'select-all-banned': {
        if (!selectAll) {
          setSelectedBannedEmails([]);
        } else {
          setSelectedBannedEmails(getSearchFilteredBannedB2bEmailRecipients());
        }
        break;
      }
    }
  };

  const renderMainDimension = () => {
    const dimensionName = appStore.chain === Chain.kRauta ? 'Laajuus' : 'Kaupan asiakasryhmät';
    const slugName = appStore.chain === Chain.kRauta ? 'info-laajuus' : 'info-kaupan-asiakasryhmat';

    return (
      <div className="main-dimension">
        <div className="section-header">
          <h3>{dimensionName}</h3>
          {infoButton({ slug: slugName })}
        </div>
        <div className="dimension-options">
          {getMainDimension() && <div className="main-options">{renderDimensionSelect(getMainDimension())}</div>}
          {getSeasonalDimension() && (
            <div className="seasonal-options">{renderDimensionSelect(getSeasonalDimension())}</div>
          )}
        </div>
      </div>
    );
  };

  const allAvailableAreas = () => {
    if (!getDelivery() || !getDelivery().maxSelectionAreas) {
      return [];
    }
    return _.map(getDelivery().maxSelectionAreas, 'area');
  };

  const toggleArea = (area: string) => {
    return (e: React.SyntheticEvent) => {
      const d = toJS(getDelivery());
      const areas = d.targetGroup.areas || allAvailableAreas();
      const checked = areaChecked(area);
      if (checked) {
        // uncheck
        d.targetGroup.areas = [...areas].filter((sel) => sel !== area);
      } else {
        // check
        d.targetGroup.areas = [...areas, area];
      }
      deliveryStore.setCurrent(d);

      triggerUpdateSelectionCounts(trigger);
    };
  };

  const toggleAllAreas = () => {
    return (e: React.SyntheticEvent<HTMLInputElement>) => {
      const d = toJS(getDelivery());
      const checked = allAreasChecked();
      if (checked) {
        // uncheck all
        d.targetGroup.areas = [];
      } else {
        // check all
        d.targetGroup.areas = null;
      }
      deliveryStore.setCurrent(d);

      triggerUpdateSelectionCounts(trigger);
    };
  };

  const areaChecked = (area: string) => {
    const { targetGroup } = getDelivery();
    if (!targetGroup.areas) {
      return true;
    }
    return _.includes(targetGroup.areas, area);
  };

  const allAreasChecked = () => {
    const { targetGroup } = getDelivery();
    if (!targetGroup.areas) {
      return true;
    }
    return _.isEqual(_.sortBy(targetGroup.areas), _.sortBy(allAvailableAreas()));
  };

  const renderAreaSection = () => {
    return (
      <div className="dimension-section" key="areas">
        <header>
          <h4 className="dimension-title">Postinumeroalueet</h4>
          {infoButton({ slug: 'info-postinumeroalueet' })}
        </header>
        {renderAreaSelection()}
      </div>
    );
  };

  const renderAreaSelection = () => {
    if (!getTemplate().targetGroupOpts.useAreas) {
      return <div>HUOM! Postinumerovalintoja ei voi muuttaa.</div>;
    }
    const { maxSelectionAreas, maxSelectionAreasLoading } = getDelivery();
    if (maxSelectionAreasLoading) {
      return <Spinner />;
    }
    if (!maxSelectionAreas || _.isEmpty(maxSelectionAreas)) {
      return (
        <Checkbox key={'area-disabled'} id={`area-disabled`} label={'Aluevalinta ei saatavilla.'} checked disabled />
      );
    }
    return (
      <div className="checkboxes">
        <Checkbox
          key={'area-all'}
          id={`area-all`}
          label={'Valitse kaikki'}
          className="checkbox-block"
          checked={allAreasChecked()}
          onChange={toggleAllAreas()}
        />
        {maxSelectionAreas.map(({ area, name, recipients }) => {
          const areaLabel = !_.isEmpty(area) ? `${area}, ${name}` : '00000, TUNTEMATON';
          return (
            <Checkbox
              key={`area-${area}`}
              id={`area-${area}`}
              label={`${areaLabel} (${recipients})`}
              checked={areaChecked(area)}
              onChange={toggleArea(area)}
            />
          );
        })}
      </div>
    );
  };

  const infoButton = (opts: { slug: string }) => {
    return (
      <InfoPopover>
        <div
          className="help-content"
          dangerouslySetInnerHTML={{
            __html: contentStore.getContentBySlug(opts.slug, { html: true }),
          }}
        />
      </InfoPopover>
    );
  };

  const toggleExpandedRow = (row: any) => {
    if (_.includes(expandedRows, row)) {
      setExpandedRows(_.without(expandedRows, row));
    } else {
      setExpandedRows([...expandedRows, row]);
    }
  };

  const toggleSelectionEmail = (recipient: GroupedSelectionEmails) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = recipient.email;
    const selected = e.currentTarget.checked;

    const updatedSelection = selected
      ? [...selectedEmails, recipient]
      : _.filter(selectedEmails, (selectedEmail) => selectedEmail.email !== email);
    setSelectedEmails(updatedSelection);
  };

  const toggleSelectionBannedEmail =
    (recipient: GroupedSelectionEmails) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const email = recipient.email;
      const selected = e.currentTarget.checked;

      const updatedSelection = selected
        ? [...selectedBannedEmails, recipient]
        : _.filter(selectedBannedEmails, (selectedBannedEmail) => selectedBannedEmail.email !== email);
      setSelectedBannedEmails(updatedSelection);
    };

  const handleAdditionalEmailEvent = () => {
    addAdditionalEmail([emailInput]);
  };

  const addAdditionalEmail = (emails: string[]) => {
    const d = toJS(getDelivery());

    // Make sure email recipients exist for the delivery
    if (!d.emailRecipients) {
      d.emailRecipients = {
        additionalEmails: [],
        bannedEmails: [],
      };
    }

    const isNotOnListAlready = (newEmail) => {
      const emailAlreadyInRecipientsList = _.find(getGroupedB2bEmailRecipients(), { email: newEmail });
      if (emailAlreadyInRecipientsList) {
        alertStore.warning({
          message: `Sähköposti ${newEmail} on jo vastaanottajalistalla.`,
        });
        return false;
      }

      const alreadyInAdditionalEmails = _.find(d.emailRecipients.additionalEmails, (email) => email === newEmail);
      if (alreadyInAdditionalEmails) {
        alertStore.warning({
          message: `Sähköposti ${newEmail} on jo lisätty omiin sähköposteihin.`,
        });
        return false;
      }
      return true;
    };

    const validatedEmails = emails
      .map((email) => email.trim())
      .filter(isValidEmail)
      .filter(isNotOnListAlready);

    d.emailRecipients.additionalEmails = [...d.emailRecipients.additionalEmails, ...validatedEmails];
    deliveryStore.setCurrent(d);

    if (validatedEmails.length > 0) {
      alertStore.success({
        message: `${validatedEmails.length} sähköpostia lisätty omiin sähköposteihin.`,
      });
    }
    if (_.find(validatedEmails, (email) => email == emailInput.trim())) {
      setEmailInput('');
    }
  };

  const handleAccept = () => {
    alertStore.success({
      message: 'Muutokset hyväksytty',
    });
    if (mobileOffersIdParam) {
      navigate(getLink('editMobileOffers', mobileOffersIdParam), { state: { disableNotification: true } });
    } else {
      navigate(getLink('editDelivery', deliveryIdParam, templateIdParam), { state: { disableNotification: true } });
    }
  };

  const renderHeader = () => {
    return <h2>{isEditable() ? 'Muokkaa' : 'Tarkastele'} kohderyhmää</h2>;
  };

  const renderFooterLoading = () => (
    <div className="target-group-form-footer">
      <div className="results">
        <span>Arvioidaan kohderyhmää...</span>
        <Spinner />
      </div>
      <div className="actions">
        <Button color="bordered" onClick={handleAccept}>
          Hyväksy
        </Button>
      </div>
    </div>
  );

  const renderFooter = () => {
    if (getDelivery().maxSelectionCountsLoading || !conceptStore.current) {
      return renderFooterLoading();
    }
    const isB2bDelivery = getDelivery().targetGroup.targetGroupType === ConceptType.B2b;
    const recipientsLabel = isB2bDelivery ? 'vastaanottajaa' : 'Plussa-asiakasta';
    const isHalvePrintRecipientCount = conceptStore.current.halvePrintRecipientCount;
    const maxSelectionCountsTotal = isHalvePrintRecipientCount
      ? getDelivery().maxSelectionCounts.total -
        getPrintRecipientCount({
          maxCount: getDelivery().maxSelectionCounts.channels.print,
          isHalvePrintRecipientCount,
        })
      : getDelivery().maxSelectionCounts.total;

    return (
      <div className="target-group-form-footer">
        {getDelivery().maxSelectionCountsLoading || !conceptStore.current ? (
          <div className="results">
            <span>Arvioidaan kohderyhmää...</span>
            <Spinner />
          </div>
        ) : (
          <div className="results">
            <span>
              Yhteensä n.{' '}
              <strong>
                {getDelivery().emailRecipients
                  ? // If email recipients exist (delivery is B2B type)
                    getBanFilteredB2bEmailRecipients().length + getDelivery().emailRecipients.additionalEmails.length
                  : // If delivery is B2B type and no email recipients exist
                    isB2bDelivery
                    ? getGroupedB2bEmailRecipients().length
                    : // If delivery is not b2b type, show maxSelectionCounts.total instead
                      maxSelectionCountsTotal}
              </strong>{' '}
              {recipientsLabel}
            </span>
          </div>
        )}
        <div className="actions">
          <Button color="bordered" onClick={handleAccept}>
            Hyväksy
          </Button>
        </div>
      </div>
    );
  };

  if (!isReady()) {
    return <Spinner />;
  }
  return (
    <DrawerContent className="target-group-form" renderHeader={renderHeader} renderFooter={renderFooter}>
      {(getMainDimension() || getSeasonalDimension()) && renderMainDimension()}
      {getAdditionalDimensions().length > 0 && renderAdditionalDimensions()}
      {getAdditionalB2BDimensions().length > 0 && renderB2BDimensions()}
      {getAdditionalB2BDimensions().length <= 0 && <div className="form-areas">{renderAreaSection()}</div>}
    </DrawerContent>
  );
};

export default inject(
  'templateStore',
  'deliveryStore',
  'dimensionsStore',
  'contentStore',
  'alertStore',
  'appStore',
  'conceptStore',
)(observer(TargetGroupForm));
