/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument, 	@typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import type { BasketOffer } from 'types/next';
import InputRadio from '../form/inputRadio';
import InputNumber from '../form/inputNumber';
import InputSelect from '../form/inputSelect';
import InputImage from '../form/inputImage';
import './basketForm.scss';
import InputText from '../form/inputText';
import { shouldLockForm } from './offerEditor';
import InputCheckbox from '../form/inputCheckbox';
import { ACBasketOfferPercentages } from 'constants/common';
import { ConceptType } from 'enums/common';
import Spinner from 'components/common/next/spinner';

interface Props {
  offer?: BasketOffer;
  setOffer: ({ offer, isValid }: { offer: BasketOffer; isValid?: boolean }) => void;
  activationLinkOverride?: boolean;
  isRetailer?: boolean;
  isEditable: boolean;
  conceptType?: ConceptType;
}

const BasketForm = ({ offer, setOffer, activationLinkOverride, isRetailer, isEditable, conceptType }: Props) => {
  const getMinimumPurchaseLimit = () => {
    if (!offer) {
      return 0;
    }

    const generalMinimumPurchaseLimit = 3;

    // For percentage offers, minimum purchase must be atleast 3e. For euro offers, minimum must be atleast same as the euro discount.
    return offer.basketOfferType === 'percentage'
      ? generalMinimumPurchaseLimit
      : Math.max(generalMinimumPurchaseLimit, _.get(offer, ['discountEuro'], 0));
  };

  const getIsMinimumPurchaseValid = () => {
    if (!offer) {
      return false;
    }
    return offer.minimumPurchase >= getMinimumPurchaseLimit();
  };

  const getIsDiscountEuroValid = () => {
    if (!offer) {
      return false;
    }
    if (offer.basketOfferType !== 'euro') {
      return true;
    }
    return offer.discountEuro >= 1;
  };

  const getInitialOffer = (type?: 'euro' | 'percentage'): BasketOffer => {
    const initialOffer: BasketOffer = {
      id: uuidv4(),
      type: 'basket',
      title: {
        fi: '',
      },
      description: {
        fi: '',
      },
      basketOfferType: type || 'percentage',
      minimumPurchase: 30,
      discountPercentageOption: '10%',
      activationLandingPage: activationLinkOverride !== undefined ? activationLinkOverride : true,
      itemLimit: 1,
      itemLimitPer: 'household',
      createTosOffer: true,
    };
    if (type === 'euro') {
      return {
        ...initialOffer,
        discountEuro: 5,
      } as BasketOffer;
    } else {
      return {
        ...initialOffer,
        discountPercentageOption: '10%',
      } as BasketOffer;
    }
  };

  useEffect(() => {
    if (!offer) {
      setOffer({ offer: getInitialOffer() });
    }
  }, []);

  const onChange = (path?: string) => {
    return (e: React.ChangeEvent<any>) => {
      const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
      const name = path || e.target.name;
      handleChange(name, value);
    };
  };

  const handleChange = (name: string, value: any) => {
    const updatedOffer = { ...offer };
    const attr = name.replace(/^offer\./, '');
    if (attr === 'basketOfferType' && value === 'euro') {
      updatedOffer.discountEuro = getInitialOffer(value).discountEuro;
      updatedOffer.discountPercentageOption = undefined;
    }
    if (attr === 'basketOfferType' && value === 'percentage') {
      updatedOffer.discountEuro = undefined;
      updatedOffer.discountPercentageOption = getInitialOffer(value).discountPercentageOption;
    }

    const isMinimumPurchaseValid = getIsMinimumPurchaseValid();
    const isDiscountEuroValid = getIsDiscountEuroValid();
    // Reset image to show loading spinner.
    if (isDiscountEuroValid && isMinimumPurchaseValid) {
      updatedOffer.image = null;
    }

    _.set(updatedOffer, attr, value);
    setOffer({
      offer: updatedOffer,
      isValid: isMinimumPurchaseValid && isDiscountEuroValid,
    });
  };

  const render = () => {
    if (!offer) {
      return null;
    }
    const minimumPurchaseLimit = getMinimumPurchaseLimit();
    const isMinimumPurchaseValid = getIsMinimumPurchaseValid();
    const isDiscountEuroValid = getIsDiscountEuroValid();

    const form = (
      <div>
        <div className="first form-section">
          <div className={`offer-image${offer.image ? '' : ' loading'}`}>
            {offer.image ? (
              <InputImage
                label="Tarjouskuva"
                onChange={({ src }) => handleChange('offer.image', src)}
                value={{ src: offer.image }}
                readOnly
                styles={{ maxHeight: 220 }}
              />
            ) : (
              <Spinner />
            )}
          </div>
          <div className="basket-opts">
            <div className="select-type form-control">
              <label>Alennuksen tyyppi</label>
              <InputRadio
                label="Prosenttialennus"
                name="offer.basketOfferType"
                value="percentage"
                defaultChecked={offer.basketOfferType === 'percentage'}
                onChange={onChange()}
              />
              <InputRadio
                label="Euromääräinen alennus"
                name="offer.basketOfferType"
                value="euro"
                defaultChecked={offer.basketOfferType === 'euro'}
                onChange={onChange()}
              />
            </div>
            <div className="form-control">
              {offer.basketOfferType === 'euro' && (
                <InputNumber
                  label="Alennus"
                  name="offer.discountEuro"
                  value={offer.discountEuro}
                  onChange={(val) => handleChange('offer.discountEuro', val)}
                  decimalScale={0}
                  min={0}
                  suffix="€"
                />
              )}
              {offer.basketOfferType === 'percentage' && (
                <InputSelect
                  label="Alennusprosentti"
                  value={offer.discountPercentageOption}
                  onChange={onChange('offer.discountPercentageOption')}
                >
                  {_.keys(ACBasketOfferPercentages).map((val) => (
                    <option key={val} value={val}>
                      {val}
                    </option>
                  ))}
                </InputSelect>
              )}
              <InputNumber
                label="Minimiostos"
                name="offer.minimumPurchase"
                value={offer.minimumPurchase}
                onChange={(val) => handleChange('offer.minimumPurchase', val)}
                decimalScale={0}
                min={minimumPurchaseLimit}
                fixedDecimalScale
                suffix="€"
                allowNegative={false}
              />
              {!isMinimumPurchaseValid && (
                <small className="error">Minimiostoksen pitää olla vähintään {minimumPurchaseLimit}€</small>
              )}
              {!isDiscountEuroValid && <small className="error">Alennuksen määrän pitää olla vähintään 1€</small>}
              {
                <InputCheckbox
                  label="Etu vaatii aktivoinnin sähköpostin saaneilta asiakkailta"
                  name="offer.activationLandingPage"
                  checked={activationLinkOverride || offer.activationLandingPage || conceptType === ConceptType.Program}
                  onChange={onChange('offer.activationLandingPage')}
                  className="form-control"
                  disabled={activationLinkOverride !== undefined || conceptType === ConceptType.Program}
                />
              }
            </div>
          </div>
        </div>
        <div className="form-section">
          <div className="input-group item-limit">
            <InputNumber
              label="Rajoitus"
              name="offer.itemLimit"
              value={offer.itemLimit || 1}
              onChange={(val) => handleChange('offer.itemLimit', val)}
              suffix="etu"
            />
            <InputSelect name="offer.itemLimitPer" value={offer.itemLimitPer} onChange={onChange('offer.itemLimitPer')}>
              <option value="person">/ asiakas</option>
              <option value="household">/ talous</option>
            </InputSelect>
          </div>
          <InputText
            label="Muut rajoitukset (emojit eivät ole tuettuja)"
            name="offer.additionalRestrictions"
            value={offer.additionalRestrictions || ''}
            onChange={onChange()}
          />
        </div>
      </div>
    );

    return (
      <div className="offer-form-basket">
        <h3>Tarjoustiedot</h3>
        {shouldLockForm(form, isEditable)}
      </div>
    );
  };

  return render();
};

export default BasketForm;
