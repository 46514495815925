/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-var-requires, @typescript-eslint/no-unsafe-member-access */
import React from 'react';
import NameLogo from './nameLogo';
import menuIcon from 'images/menu.svg';
import crossIcon from 'images/cross.svg';

interface Props {
  menuOpen: boolean;
  admin?: boolean;
  onClick(): void;
  logoLink?: string;
}

const MobileHeader = ({ menuOpen, onClick, admin, logoLink }: Props) => (
  <div className="header mobile-header">
    <div className="mobile-header-content">
      <div className={`menu-button ${menuOpen ? 'close' : 'open'}`} onClick={onClick}>
        <img src={menuOpen ? crossIcon : menuIcon} alt="menu" />
      </div>
      <NameLogo toPath={logoLink} />
      {admin ? <span>Admin</span> : null}
    </div>
  </div>
);

export default MobileHeader;
