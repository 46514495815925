/* eslint-disable @typescript-eslint/no-floating-promises, @typescript-eslint/no-unsafe-member-access, react/no-unescaped-entities, @typescript-eslint/no-explicit-any, react/no-unescaped-entities, @typescript-eslint/no-unsafe-assignment, react/no-unescaped-entities  */
import type { DeliveryLanguage } from 'components/next/types';
import _ from 'utils/lodash';
import { castDate, getChain } from 'utils/helpers';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import UserStore from 'stores/userStore';
import { getLink } from '../../../routes';
import { isMobile } from '../../../utils/helpers';
import Button from '../../../../../common/next/form/button';
import ToggleButton from 'components/common/next/form/toggleButton';
import CloseButton from '../../primitives/closeButton';
import TargetSelectionBox from './sections/targetSelectionBox';
import OffersBox from './sections/offersBox';
import ChannelsBox from './sections/channelsBox';
import DatesBox from './sections/datesBox';
import TemplateStore, { DeliveryTemplateWithMeta } from 'stores/next-retailer/templateStore';
import DeliveryStore, {
  Delivery,
  DeliveryRelations,
  DeliveryWithMeta,
  DeliveryPreviewsOpts,
} from 'stores/next-retailer/deliveryStore';
import * as date from 'date-fns';
import './deliveryForm.scss';
import Alerts from 'components/common/next/alert/alerts';
import AlertStore from 'stores/next/alerts';
import InfoPopover from '../../common/infoPopover';
import { ReactComponent as ChevronRight } from '@kesko/icons/nav/icon-chevron-right.svg';
import ContentStore from 'stores/next-retailer/contentStore';
import { AxiosError } from 'axios';
import ConceptStore from 'stores/next-retailer/conceptStore';
import DimensionsStore from 'stores/next/dimensions';
import DescriptionBox from './sections/descriptionBox';
import GitHash from '../../../../../common/next/gitHash';
import { ConceptType, DeliveryState, DeliveryChannelName, Chain } from 'enums/common';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import Spinner from 'components/common/next/spinner';

interface InjectedProps {
  templateStore?: TemplateStore;
  deliveryStore?: DeliveryStore;
  contentStore?: ContentStore;
  userStore?: UserStore;
  alertStore?: AlertStore;
  conceptStore?: ConceptStore;
  dimensionsStore?: DimensionsStore;
}

export enum CreateMode {
  new = 'new',
  automaticDisabled = 'automatic-disabled',
  automatic = 'automatic',
}

const TemplateForm = ({
  templateStore,
  deliveryStore,
  contentStore,
  userStore,
  alertStore,
  conceptStore,
  dimensionsStore,
}: InjectedProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { templateId: templateIdParam, deliveryId: deliveryIdParam } = useParams();
  const [isWaiting, setIsWaiting] = useState(false);

  const getTemplate = () => {
    return _.find(templateStore.templates, { id: templateIdParam });
  };

  const getDelivery = () => {
    // use current delivery
    return deliveryStore.current as Delivery & DeliveryRelations & DeliveryWithMeta;
  };

  const getConceptType = () => {
    const concept = _.find(conceptStore.concepts, { id: _.get(getTemplate(), 'concept') });
    return _.get(concept, 'type');
  };

  const getConceptId = () => {
    const concept = _.find(conceptStore.concepts, { id: _.get(getTemplate(), 'concept') });
    return _.get(concept, 'id');
  };

  const getConcept = () => {
    return _.find(conceptStore.concepts, { id: getConceptId() });
  };

  const getTemplates = () => {
    return _.chain(templateStore.templates).filter({ concept: getConceptId() }).sortBy('deadline').value();
  };

  const getDeliveries = () => {
    return _.chain(deliveryStore.deliveries)
      .filter(({ deliveryTemplate }) =>
        _.includes(
          getTemplates().map((t) => t.id),
          deliveryTemplate,
        ),
      )
      .orderBy((d) => d.startDate, 'desc')
      .value();
  };

  const isNew = () => {
    return (deliveryIdParam as CreateMode) === CreateMode.new;
  };

  const isAutomaticDisabled = () => {
    return (deliveryIdParam as CreateMode) === CreateMode.automaticDisabled;
  };

  const isAutomatic = () => {
    return (deliveryIdParam as CreateMode) === CreateMode.automatic;
  };

  const getDaysBeforeEdit = () => {
    const template = getTemplate();
    return template.firstEditDate
      ? date.differenceInDays(castDate(template.firstEditDate), date.startOfToday())
      : date.differenceInDays(castDate(template.firstStartDate), date.startOfToday());
  };

  const isProgramParticipationDisabled = () => {
    const concept = getConcept();
    if (
      getConceptType() === ConceptType.Program &&
      getDelivery().state === DeliveryState.Draft &&
      concept.maxTemplateParticipations > -1
    ) {
      const participatedTemplateCount = _.chain(getDeliveries())
        .filter((d) => d.state === DeliveryState.Confirmed)
        .map((d) => d.deliveryTemplate)
        .uniq()
        .size()
        .value();
      return participatedTemplateCount >= concept.maxTemplateParticipations;
    }
    return false;
  };

  const hasEditStarted = () => {
    return getDaysBeforeEdit() <= 0;
  };

  const isReady = () => {
    const template = getTemplate();
    const delivery = getDelivery();
    return template && template.contentTemplates && delivery && delivery.deliverySlots && getConceptType();
  };

  const getDeliveryLanguages = () => {
    const delivery = getDelivery();
    if (delivery.targetGroup.languages.length) {
      return delivery.targetGroup.languages;
    }
    // default to both if no language data is present
    return ['fi', 'sv'];
  };

  const getTemplateLanguages = () => {
    return getTemplate().languages;
  };

  const getDefaultLanguages = () => {
    return getTemplate().defaultLanguages || [];
  };

  const hasEmail = () => {
    const delivery = getDelivery();
    if (!delivery) {
      return false;
    }

    return _.get(delivery, ['targetGroup', 'channels', 'email'], 0) !== 0 && !_.isNil(delivery.emailContentTemplate);
  };

  const hasPrint = () => {
    const delivery = getDelivery();
    if (!delivery) {
      return false;
    }

    return _.get(delivery, ['targetGroup', 'channels', 'print'], 0) !== 0 && !_.isNil(delivery.printContentTemplate);
  };

  useEffect(() => {
    const init = async () => {
      let delivery = getDelivery() as DeliveryWithMeta;
      let template = getTemplate();
      let concept = getConcept();

      if (isNew() || delivery?.id !== deliveryIdParam) {
        delivery = undefined;
      }
      if (template?.id !== templateIdParam) {
        template = undefined;
      }
      if (template?.concept !== concept?.id) {
        concept = undefined;
      }

      if (!delivery || !template) {
        template = (await templateStore.getDeliveryTemplate(templateIdParam)) as DeliveryTemplateWithMeta;
      }
      if (!delivery || !concept) {
        concept = await conceptStore.getConcept(template.concept);
      }

      if (!delivery) {
        if (isNew() || isAutomatic() || isAutomaticDisabled()) {
          delivery = await templateStore.getDefaultDeliveryForTemplate(template, userStore.me);
          if (getDefaultLanguages().includes('sv')) {
            delivery.targetGroup.languages.push('sv');
          }
        } else {
          delivery = deliveryStore.getCollectionItem(deliveryIdParam);
          if (!delivery || !delivery.deliveryOffers || !delivery.deliveryFields || !delivery.deliverySlots) {
            // fetch full delivery if one is not in collection
            delivery = await deliveryStore.getDelivery(deliveryIdParam);
          }
        }
        deliveryStore.setCurrent(delivery);
      }

      if (!delivery.maxSelectionCounts && delivery.maxSelectionCountsLoading === undefined) {
        deliveryStore.getMaxSelectionCountsForDelivery(getDelivery());
      }
      delivery.targetGroup.targetGroupType === ConceptType.B2b &&
        (await dimensionsStore.getSelectionEmails(getDelivery().targetGroup));

      if (!location.state?.disableNotification) {
        const deliveriesWithParticipation = checkParticipation(delivery.deliveryTemplate);
        if (deliveriesWithParticipation.length > 0) {
          alertStore.info({
            message: <span>Olet jo kerran osallistunut lähetykseen "{template.title.fi}".</span>,
          });
        }
      }

      if (!delivery.emailPreviewLarge) {
        updatePreview({
          email: {
            image: hasEmail(),
            html: hasEmail(),
          },
          print: {
            image: hasPrint(),
            pdf: hasPrint(),
          },
        });
      }
    };
    init();
    return () => {
      deliveryStore.unsetCurrent();
    };
  }, []);

  const isValidDeliveryOfferNumber = () => {
    const template = getTemplate();

    const offersEnabled = (template.offerOptions && template.offerOptions.length > 0) || template.offerOpts.allowOwn;
    if (offersEnabled) {
      const { min } = template.offerOpts;
      if (!min) {
        return true;
      }
      return getDelivery().deliveryOffers.length >= min;
    }
    return true;
  };

  const updatePreview = (opts?: DeliveryPreviewsOpts) => {
    deliveryStore.updateDeliveryPreviews({ delivery: getDelivery(), ref: { current: true }, opts });
  };

  const setDeliveryField = (field: string, value: any) => {
    const d = toJS(getDelivery());
    _.set(d, field, value);
    deliveryStore.setCurrent(d);
  };

  const handleCancel = () => {
    const confirmed = window.confirm('Oletko varma, että haluat poistua tallentamatta muutoksia?');
    if (confirmed) {
      const template = getTemplate();
      templateStore.resetDeliveryTemplateInCollection(template.id);
      navigate(getLink('concept', template.concept));
    }
  };

  const handleDelete = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const confirmed = window.confirm('Oletko varma, että haluat poistaa lähetyksen? Tätä ei voida perua.');
    if (confirmed) {
      setIsWaiting(true);
      try {
        const template = getTemplate();
        await deliveryStore.deleteDelivery(getDelivery().id);
        navigate(getLink('concept', template.concept));
        alertStore.success({
          message: <span>Luonnos "{template.title.fi}" poistettu onnistuneesti.</span>,
        });
      } catch (error) {
        alertStore.error({
          message: 'Virhe poistaessa luonnosta.',
          ttl: 0,
        });
      }
      window.scrollTo({ top: 0 });
      setIsWaiting(false);
    }
  };

  const setDraftAndDelete = async (e: React.SyntheticEvent) => {
    e.persist();
    const delivery = toJS(getDelivery());
    delivery.state = DeliveryState.Draft;
    // although saveDelivery might fail partially, it should be still ok to delete it normally
    await deliveryStore.saveDelivery(delivery, getConcept().halvePrintRecipientCount);
    await handleDelete(e);
  };

  const checkParticipation = (id: string) => {
    const participation = getDeliveries().filter((delivery) => delivery.deliveryTemplate === id);
    return participation;
  };

  const toggleAutomaticParticipationDisable = (exclude: boolean) => {
    return async (e: React.SyntheticEvent) => {
      const template = getTemplate();
      e.preventDefault();
      setIsWaiting(true);
      await conceptStore.storeParticipateAutomaticallyExcludeTemplate(getConceptId(), template.id, exclude);
      navigate(getLink('concept', template.concept));
      setIsWaiting(false);
    };
  };

  const renderContactEmail = () => {
    const chain = getChain([userStore.me.chainId]);
    switch (chain) {
      case Chain.kRuoka:
        return <a href="mailto:tuki.kmarkkinointi@kesko.fi">tuki.kmarkkinointi@kesko.fi</a>;
      case Chain.kRauta:
        return <a href="mailto:k-rauta@kesko.fi?subject=K-Rauta K Markkinointi">k-rauta@kesko.fi</a>;
      case Chain.onninen:
        return <a href="mailto:aineistot@onninen.com">aineistot@onninen.com</a>;
      default:
        return null;
    }
  };

  const handleSubmit = (state: DeliveryState) => {
    return async (e: React.SyntheticEvent) => {
      e.preventDefault();
      const delivery = toJS(getDelivery());
      const template = getTemplate();
      delivery.state = state;
      delivery.endDate = date.format(
        date.addDays(castDate(delivery.startDate), template.deliveryOpts.minimumLength || 7),
        'yyyy-MM-dd',
      );
      delivery.lastEditDate = template.lastEditDate
        ? date.format(castDate(template.lastEditDate), 'yyyy-MM-dd')
        : date.format(castDate(template.deadline), 'yyyy-MM-dd');

      alertStore.clear();
      setIsWaiting(true);
      try {
        const saved = await deliveryStore.saveDelivery(
          delivery as Delivery & DeliveryRelations,
          getConcept().halvePrintRecipientCount,
        );
        deliveryStore.unsetCurrent();
        await deliveryStore.getDelivery(saved.id);
        if (saved && saved.hasFailedInAC) {
          deliveryStore.setCurrent(saved);
          refreshSelectionCounts();
          navigate(getLink('editDelivery', saved.id, template.id));
        } else {
          navigate(getLink('concept', template.concept));
        }
        if (saved.hasFailedInAC) {
          alertStore.error({
            message: (
              <span>
                Mahdollinen virhe osallistumisessa. Yritä tallentaa lähetys uudelleen. Jos virhe toistuu, ota yhteyttä{' '}
                {renderContactEmail()}
              </span>
            ),
            ttl: 0,
          });
        } else if (state === DeliveryState.Draft) {
          alertStore.success({
            message: (
              <span>
                Lähetys "{template.title.fi}" tallennettu tilaan "luonnos". Jos haluat poistaa luonnoksen kokonaan,
                klikkaa muokkausnäkymästä vielä "Poista luonnos".
              </span>
            ),
          });
        } else if (state === DeliveryState.Confirmed) {
          alertStore.success({
            message: (
              <span>
                <strong key="thank">Kiitos! </strong>Lähetys "{template.title.fi}" ajastettu lähtemään{' '}
                {date.format(castDate(castDate(delivery.startDate)), 'd.M.yyyy')}
              </span>
            ),
          });
        }
      } catch (error) {
        let errorMessage = <span>Virhe osallistumisessa. Ota yhteyttä {renderContactEmail()}</span>;
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          //  handle errors
          const message: string = _.get(axiosError, ['response', 'data', 'message'], '');
          if (message.includes('Not enough delivery offers')) {
            errorMessage = (
              <span>
                Lisää vähintään {template.offerOpts.min} etu{template.offerOpts.min > 1 ? 'a' : ''}
              </span>
            );
          } else if (message.includes('Not enough required recommendations')) {
            errorMessage = <span>Lisää vähintään {template.offerOpts.requiredRecommendations} suositusetu(a).</span>;
          } else if (message.includes('Too many delivery offers')) {
            errorMessage = <span>Etuja sallittu yhteensä {template.offerOpts.max} kpl!</span>;
          } else if (message.includes(`Can't start more than 4 deliveries within 7 days`)) {
            errorMessage = <span>Voit aloittaa enintään 4 omaa lähetystä 7 päivän sisällä toisistaan</span>;
          } else {
            // unknown error
            errorMessage = (
              <span>
                Virhe: "{message}". Ota yhteyttä {renderContactEmail()}
              </span>
            );
          }
        }
        alertStore.error({
          message: errorMessage,
          ttl: 0,
        });
        window.scrollTo({ top: 0 });
      }
      setIsWaiting(false);
    };
  };

  // add template counter
  const renderFooter = () => {
    const template = getTemplate();
    const delivery = getDelivery();
    const zeroWarningDiv = (msg: string | undefined = undefined) => (
      <div className="warning">Huomio: Lähetyksellä on 0{msg && ' ' + msg} vastaanottajaa</div>
    );
    const validationError =
      deliveryStore.numberOfValidationErrors === 0 ? null : (
        <div className="warning">Tarkista, että olet täydentänyt kaikki lähetyksen TODO-merkinnät</div>
      );
    const zeroDeliveryTotal =
      delivery.maxSelectionCounts && delivery.maxSelectionCounts.total === 0 && zeroWarningDiv();
    const zeroDeliveryEmail =
      template.channels.find((c) => c.name === DeliveryChannelName.Email) &&
      delivery.maxSelectionCounts &&
      delivery.maxSelectionCounts.channels.email === 0 &&
      zeroWarningDiv('sähköpostin');
    const zeroDeliveryPrint =
      template.channels.find((c) => c.name === DeliveryChannelName.Print) &&
      delivery.maxSelectionCounts &&
      delivery.maxSelectionCounts.channels.print === 0 &&
      zeroWarningDiv('printin');
    const zeroDeliveryWarning = validationError || zeroDeliveryTotal || zeroDeliveryEmail || zeroDeliveryPrint || null;

    return (
      <div className="footer-buttons">
        <GitHash />
        <Button color="bordered" onClick={handleCancel} disabled={isWaiting}>
          Takaisin
        </Button>
        {!(isNew() || isAutomatic() || isAutomaticDisabled()) && delivery.state === 'draft' && (
          <Button color="bordered" onClick={handleDelete} disabled={isWaiting}>
            Poista luonnos
          </Button>
        )}
        {(hasEditStarted() || delivery.state !== DeliveryState.Draft) && (
          <Button
            color="bordered"
            onClick={!hasEditStarted() ? setDraftAndDelete : handleSubmit(DeliveryState.Draft)}
            disabled={isWaiting}
          >
            {delivery.state === DeliveryState.Draft ? 'Tallenna ja jatka myöhemmin' : 'Peru osallistuminen'}
          </Button>
        )}
        {(isAutomatic() || isAutomaticDisabled()) && delivery.state === DeliveryState.Draft && (
          <Button color="default" onClick={toggleAutomaticParticipationDisable(isAutomatic())} disabled={isWaiting}>
            {isAutomatic() ? 'Peru automaattinen osallistuminen' : 'Osallistu automaattisesti'}
          </Button>
        )}
        {(hasEditStarted() || delivery.state === DeliveryState.Draft) && (
          <Button
            color="default"
            onClick={handleSubmit(DeliveryState.Confirmed)}
            disabled={
              isWaiting || isProgramParticipationDisabled() || !!validationError || !isValidDeliveryOfferNumber()
            }
          >
            {delivery.state === DeliveryState.Draft ? 'Osallistu' : 'Tallenna'}
          </Button>
        )}
        {zeroDeliveryWarning}
      </div>
    );
  };

  const infoButton = (opts: { slug: string; link?: string }) => {
    return (
      <InfoPopover>
        <div
          className="help-content"
          dangerouslySetInnerHTML={{
            __html: contentStore.getContentBySlug(opts.slug, { html: true }),
          }}
        />
        {opts.link && (
          <footer>
            <a href={opts.link} target="_blank" rel="noopener noreferrer">
              Avaa ohjeet uudessa ikkunassa
              <ChevronRight />
            </a>
          </footer>
        )}
      </InfoPopover>
    );
  };

  const refreshSelectionCounts = () => {
    deliveryStore.getMaxSelectionCountsForDelivery(getDelivery());
  };

  const toggleLanguage = (language: DeliveryLanguage) => {
    let languages = getDeliveryLanguages();
    if (languages.includes(language)) {
      languages = languages.filter((item) => item !== language);
    } else {
      languages.push(language);
    }
    setDeliveryField('targetGroup.languages', languages);

    refreshSelectionCounts();
  };

  const toggleButtonDisabled = (lang: DeliveryLanguage) => {
    const languages = getDeliveryLanguages();
    return languages.includes(lang) && languages.length === 1;
  };

  const renderDetailText = (lang: DeliveryLanguage) => (
    <span className="detail-text">
      {toggleButtonDisabled(lang) ? 'Lähetyksessä on oltava valittuna yksi kieli' : ''}
    </span>
  );

  const renderSvDetailText = () => (
    <span className="detail-text">{'Muista muokata sisällöt valitsemillesi kielille!'}</span>
  );

  if (!isReady()) {
    return <Spinner />;
  }
  const render = () => {
    const template = getTemplate();
    const templateLanguages = getTemplateLanguages();
    const delivery = getDelivery();
    const deliveryLanguages = getDeliveryLanguages();
    const concept = getConcept();
    const conceptType = getConceptType();
    const hasOfferOptions = template.offerOptions && template.offerOptions.length > 0;
    const offersEnabled = hasOfferOptions || template.offerOpts.allowOwn;
    const showDeliveryOffers = delivery.deliveryOffers.length !== 0 ? true : false;
    return (
      <>
        <div className="delivery-form main-container full-page">
          <div className="header">
            <div className="full-page-content">
              <div className="text-center">
                <h1 className="form-title">{template.title.fi}</h1>
                <CloseButton onClick={handleCancel} />
              </div>
            </div>
          </div>
          <div className="main-content">
            <div className="full-page-content">
              {template.description && (
                <div className="description">
                  <span>{template.description.fi}</span>
                </div>
              )}
              <div className="section-header">
                <h2>Kielivalinnat</h2>
              </div>
              <div className="box language-selection">
                {templateLanguages.includes('fi') && (
                  <div className="toggle-wrapper">
                    <span className="language-name">Suomi</span>
                    <ToggleButton
                      isSelected={deliveryLanguages.includes('fi')}
                      value="fi"
                      toggle={toggleLanguage}
                      disabled={toggleButtonDisabled('fi') || !hasEditStarted()}
                    />
                    {renderDetailText('fi')}
                  </div>
                )}
                {templateLanguages.includes('sv') && (
                  <div className="toggle-wrapper">
                    <span className="language-name">Ruotsi</span>
                    <ToggleButton
                      isSelected={deliveryLanguages.includes('sv')}
                      value="sv"
                      toggle={toggleLanguage}
                      disabled={toggleButtonDisabled('sv') || !hasEditStarted()}
                    />
                    {renderDetailText('sv')}
                    {deliveryLanguages.includes('sv') ? renderSvDetailText() : null}
                  </div>
                )}
              </div>

              {offersEnabled && (
                <React.Fragment>
                  <div className="section-header">
                    <h2>
                      Tuotenostot ja edut
                      {template.offerOpts.min > 0 && (
                        <span className="min-offer-count">
                          {' '}
                          (vähintään {template.offerOpts.min} {template.offerOpts.min > 1 ? 'etua' : 'etu'})
                        </span>
                      )}
                    </h2>
                    {infoButton({ slug: 'info-edut', link: getLink('help', 'info-edut') })}
                  </div>
                  <OffersBox
                    deliveryOffers={toJS(delivery.deliveryOffers)}
                    delivery={delivery}
                    template={template}
                    disabled={!hasEditStarted()}
                  />
                </React.Fragment>
              )}

              <div className="section-header">
                <h2>Kohderyhmä</h2>
                {infoButton({ slug: 'info-kohderyhma', link: getLink('help', 'info-kohderyhma') })}
              </div>
              <TargetSelectionBox
                delivery={delivery}
                template={template}
                disabled={!hasEditStarted()}
                conceptType={conceptType as ConceptType}
              />
              <div className="section-header">
                <h2>Kanavat ja sisältö</h2>
                {infoButton({ slug: 'info-kanavat', link: getLink('help', 'info-kanavat') })}
              </div>
              <ChannelsBox
                delivery={delivery}
                template={template}
                isHalvePrintRecipientCount={concept.halvePrintRecipientCount}
                conceptType={conceptType as ConceptType}
                setDeliveryField={setDeliveryField}
                updatePreview={updatePreview}
                disabled={!hasEditStarted()}
              />

              <div className="section-header">
                <h2>Ajankohta</h2>
                {infoButton({ slug: 'info-ajankohta', link: getLink('help', 'info-ajankohta') })}
              </div>
              <DatesBox
                delivery={delivery}
                showDates={showDeliveryOffers}
                template={template}
                setDeliveryField={setDeliveryField}
                conceptType={conceptType as ConceptType}
                disabled={!hasEditStarted()}             />

              <div className="section-header">
                <h2>Lähetyksen kuvaus</h2>
                {infoButton({ slug: 'info-lahetyskuvaus' })}
              </div>
              <DescriptionBox delivery={delivery} />

              <Alerts />
              <pre className="debug">{JSON.stringify(toJS(delivery), null, 2)}</pre>
              {isMobile() && <div className="mobile-footer">{renderFooter()}</div>}
            </div>
          </div>
          {!isMobile() && <div className="footer with-space">{renderFooter()}</div>}
        </div>
        <Outlet />
      </>
    );
  };

  return render();
};

export default inject(
  'templateStore',
  'deliveryStore',
  'dimensionsStore',
  'contentStore',
  'userStore',
  'alertStore',
  'conceptStore',
)(observer(TemplateForm));
