/* eslint-disable 	@typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return,  @typescript-eslint/no-floating-promises, @typescript-eslint/no-misused-promises */
import React, { SyntheticEvent, Fragment, createRef } from 'react';
import _ from 'utils/lodash';
import * as date from 'date-fns';
import Button from '../../../../../../common/next/form/button';
import { getLink } from 'components/retailer/next/routes';
import './channelsBox.scss';
import { Link } from 'react-router-dom';
import ToggleButton from 'components/common/next/form/toggleButton';
import DeliveryStore, {
  DeliveryWithMeta,
  getFirstPossibleStartDateForDelivery,
  DeliveryPreviewsOpts,
} from 'stores/next-retailer/deliveryStore';
import DimensionsStore from 'stores/next/dimensions';
import { DeliveryTemplateWithMeta } from 'stores/next-retailer/templateStore';
import { inject, observer } from 'mobx-react';
import { ReactComponent as IconPreview } from '@kesko/icons/action/icon-preview.svg';
import { ReactComponent as IconNotice } from '@kesko/icons/attention/icon-notice.svg';
import { NumericFormat } from 'react-number-format';
import { castDate, isKRautaChain, isKRuokaOnlyChain, isOnninenChain } from 'utils/helpers';
import { getPrintRecipientCount } from '../../../../utils/helpers';
import { kRautaChainIds } from 'constants/common';
import { ConceptType, DeliveryChannelName } from 'enums/common';
import Spinner from 'components/common/next/spinner';

interface Props {
  delivery: DeliveryWithMeta;
  template: DeliveryTemplateWithMeta;
  isHalvePrintRecipientCount: boolean;
  conceptType: ConceptType;
  setDeliveryField: (field: string, value: any) => any;
  updatePreview: (opts?: DeliveryPreviewsOpts) => void;
  disabled?: boolean;
}

interface InjectedProps extends Props {
  deliveryStore: DeliveryStore;
  dimensionsStore: DimensionsStore;
}

interface State {
  emailSelectionEditable: boolean;
  printSelectionEditable: boolean;
  hasEmailChannel: boolean;
  hasPrintChannel: boolean;
  isEmailChannelLocked: boolean;
  isPrintChannelLocked: boolean;
  emailRecipientsEditable: boolean;
}

@inject('deliveryStore', 'dimensionsStore')
@observer
class ChannelsBox extends React.Component<Props, State> {
  emailSelectionInput = createRef<HTMLInputElement>();
  printSelectionInput = createRef<HTMLInputElement>();

  constructor(props) {
    super(props);
    const channelNames = this.template.channels.map((c) => c.name);
    const contentTemplateNames = this.template.contentTemplates.map((c) => c.channel);
    const emailChannelOpts = _.find(this.template.channels, { name: DeliveryChannelName.Email });
    const printChannelOpts = _.find(this.template.channels, { name: DeliveryChannelName.Print });
    // Set channel defaults from delivery template if delivery is opened first time
    if (!this.delivery.id) {
      // Only need to change the delivery's channel value if default is not selected (0 = not selected)
      emailChannelOpts &&
        !emailChannelOpts.default &&
        _.set(this.delivery.targetGroup.channels, DeliveryChannelName.Email, 0);
      printChannelOpts &&
        !printChannelOpts.default &&
        _.set(this.delivery.targetGroup.channels, DeliveryChannelName.Print, 0);
    }
    this.state = {
      emailSelectionEditable: false,
      printSelectionEditable: false,
      hasEmailChannel:
        _.includes(channelNames, DeliveryChannelName.Email) &&
        _.includes(contentTemplateNames, DeliveryChannelName.Email),
      hasPrintChannel:
        _.includes(channelNames, DeliveryChannelName.Print) &&
        _.includes(contentTemplateNames, DeliveryChannelName.Print),
      isEmailChannelLocked: emailChannelOpts && emailChannelOpts.locked,
      isPrintChannelLocked: printChannelOpts && printChannelOpts.locked,
      emailRecipientsEditable: !kRautaChainIds.includes(this.deliveryChainId),
    };
  }

  get injected() {
    return this.props as InjectedProps;
  }

  get deliveryStore() {
    return this.injected.deliveryStore;
  }

  get delivery() {
    return this.props.delivery;
  }

  get template() {
    return this.props.template;
  }

  get deliveryId() {
    return this.delivery.id || 'new';
  }

  get templateId() {
    return this.delivery.deliveryTemplate;
  }

  get maxSelectionCounts() {
    return this.delivery.maxSelectionCounts;
  }

  get deliveryChainId() {
    return this.delivery.chainId;
  }

  get b2bEmailRecipients() {
    return this.injected.dimensionsStore.b2bEmailRecipients;
  }

  get groupedB2bEmailRecipients() {
    return this.injected.dimensionsStore.groupedB2bEmailRecipients;
  }

  get deliveryStaleBannedEmails() {
    if (this.delivery.emailRecipients) {
      const { emailRecipients } = this.delivery;
      const { bannedEmails: deliveryBannedEmails } = emailRecipients;
      const existingBannedEmails = [];
      let staleBannedEmails = [];

      if (this.groupedB2bEmailRecipients) {
        this.groupedB2bEmailRecipients.filter((email) => {
          if (deliveryBannedEmails.includes(email.email)) {
            existingBannedEmails.push(email);
            return true;
          }
          return false;
        });

        // Get the emails that are in deliveryBannedEmails but not in existingBannedEmails
        staleBannedEmails = deliveryBannedEmails.filter((email) => {
          return !existingBannedEmails.find((e) => e.email === email);
        });

        return staleBannedEmails;
      }
    }
    return [];
  }

  get channelSelectionValues() {
    const { email: emailSelection, print: printSelection } = this.delivery.targetGroup.channels;
    const emailMax = this.maxSelectionCounts ? this.maxSelectionCounts.channels.email : 0;
    const emailSelectionValue = emailSelection === -1 ? emailMax : this.delivery.targetGroup.channels.email;
    const preliminaryPrintMax = this.maxSelectionCounts ? this.maxSelectionCounts.channels.print : 0;
    const printMax = getPrintRecipientCount({
      maxCount: preliminaryPrintMax,
      isHalvePrintRecipientCount: this.props.isHalvePrintRecipientCount,
    });

    const printSelectionValue = printSelection === -1 ? printMax : this.delivery.targetGroup.channels.print;
    return {
      email: {
        isSelected: emailSelection !== 0,
        selection: emailSelection,
        max: emailMax,
        selectionValue: emailSelectionValue,
      },
      print: {
        isSelected: printSelection !== 0,
        selection: printSelection,
        max: printMax,
        selectionValue: printSelectionValue,
      },
    };
  }

  componentDidUpdate(_, prevState: State) {
    if (!prevState.emailSelectionEditable && this.state.emailSelectionEditable) {
      this.emailSelectionInput.current.focus();
    }
    if (!prevState.printSelectionEditable && this.state.printSelectionEditable) {
      this.printSelectionInput.current.focus();
    }
  }

  refreshSelectionCounts = () => {
    this.deliveryStore.getMaxSelectionCountsForDelivery(this.delivery);
  };
  debouncedRefreshSelectionCounts = _.debounce(this.refreshSelectionCounts, 500);

  updateDates = () => {
    const { startDate } = this.delivery;
    const { conceptType } = this.props;
    const firstPossibleStartDate = getFirstPossibleStartDateForDelivery(this.delivery, this.template, conceptType);
    if (new Date(startDate) < firstPossibleStartDate) {
      // reset dates
      this.delivery.startDate = date.format(castDate(firstPossibleStartDate), 'yyyy-MM-dd');
      this.delivery.offerStartDate = date.format(castDate(firstPossibleStartDate), 'yyyy-MM-dd');
      this.delivery.offerEndDate = date.format(
        castDate(date.addDays(firstPossibleStartDate, _.get(this.template, ['offerOpts', 'defaultLength'], 7))),
        'yyyy-MM-dd',
      );
    }
  };

  getPriceForChannel = (channel: DeliveryChannelName.Email | DeliveryChannelName.Print) => {
    if (channel === DeliveryChannelName.Print && this.template.deliveryOpts.printPriceEstimateOverride) {
      const overridePrice = this.template.deliveryOpts.printPriceEstimateOverride;
      const { selectionValue } = this.channelSelectionValues[channel];
      return selectionValue * overridePrice;
    }

    const pricing = _.find(this.template.pricing, { channel });
    const { selectionValue } = this.channelSelectionValues[channel];
    if (!pricing || !pricing.components) {
      return 0;
    }
    let price = 0;
    for (const component of pricing.components) {
      const unitPrice = _.chain(component.steps)
        .orderBy((step) => step.from)
        .findLast((step) => step.from <= selectionValue)
        .value();
      if (unitPrice) {
        price += selectionValue * unitPrice.price;
      }
    }
    return price;
  };

  handleSelectionChange = (channel: DeliveryChannelName.Email | DeliveryChannelName.Print) => {
    return async (e: SyntheticEvent) => {
      let value = Number((e.target as HTMLInputElement).value);
      if (value >= _.get(this.maxSelectionCounts.channels, channel)) {
        value = -1;
      }
      await this.props.setDeliveryField(`targetGroup.channels.${channel}`, value);
      this.debouncedRefreshSelectionCounts();
      this.updateDates();
    };
  };

  toggleChannelSelection = (channel: DeliveryChannelName.Email | DeliveryChannelName.Print) => {
    const delivery = this.delivery;
    const { updatePreview } = this.props;
    return async () => {
      // Since recipient numbers may change based on channel selection, force selected channels to -1 on toggle
      if (delivery.targetGroup.channels?.email !== 0) {
        await this.props.setDeliveryField(`targetGroup.channels.email`, -1);
      }
      if (delivery.targetGroup.channels?.print !== 0) {
        await this.props.setDeliveryField(`targetGroup.channels.print`, -1);
      }
      // Channel selection before toggling. 0 means channel is not selected.
      const value = _.get(this.delivery.targetGroup.channels, channel);
      await this.props.setDeliveryField(`targetGroup.channels.${channel}`, value === 0 ? -1 : 0);
      this.debouncedRefreshSelectionCounts();
      this.updateDates();

      // Update channel preview is selection was changed from not selected (0) to selected.
      const updateEmail = value === 0 && !_.isNil(delivery.emailContentTemplate);
      const updatePrint = value === 0 && !_.isNil(delivery.printContentTemplate);

      const previewOpts =
        channel === DeliveryChannelName.Email
          ? {
              email: {
                image: updateEmail,
                html: updateEmail,
              },
              print: {
                image: false,
                pdf: false,
              },
            }
          : {
              email: {
                image: false,
                html: false,
              },
              print: {
                image: updatePrint,
                pdf: updatePrint,
              },
            };

      updatePreview(previewOpts);
    };
  };

  editChannelSelection = (channel: DeliveryChannelName.Email | DeliveryChannelName.Print) => {
    return () => {
      if (channel === DeliveryChannelName.Email) {
        this.setState({ emailSelectionEditable: true });
      }
      if (channel === DeliveryChannelName.Print) {
        this.setState({ printSelectionEditable: true });
      }
    };
  };

  blurChannelSelectionEdit = () => {
    this.setState({ printSelectionEditable: false, emailSelectionEditable: false });
  };

  renderEmailPreview = () => {
    const delivery = this.delivery;
    if (!delivery) {
      return null;
    }

    const { isSelected: emailSelected } = this.channelSelectionValues.email;

    if (!emailSelected) {
      return <IconNotice title="Kanava ei valittu" />;
    }

    if (!delivery.emailContentTemplate) {
      return <IconNotice title="Pohjaa ei valittu" />;
    }

    return !delivery.emailPreviewThumb?.[this.deliveryStore.previewLanguage] ||
      delivery.emailPreviewThumbLoading === true ? (
      <div>
        <Spinner />
      </div>
    ) : (
      <Link to="preview/delivery/email/new">
        <div className="preview-wrapper">
          <img src={delivery.emailPreviewThumb[this.deliveryStore.previewLanguage]} alt="preview" />
        </div>
        <div className="preview-icon">
          <IconPreview />
        </div>
      </Link>
    );
  };

  renderPrintPreview = () => {
    const delivery = this.delivery;
    if (!delivery) {
      return null;
    }

    const { isSelected: printSelected } = this.channelSelectionValues.print;

    if (!printSelected) {
      return <IconNotice title="Kanava ei valittu" />;
    }

    if (!delivery.printContentTemplate) {
      return <IconNotice title="Pohjaa ei valittu" />;
    }

    return !delivery.printPreviewThumb?.[this.deliveryStore.previewLanguage] ||
      delivery.printPreviewThumbLoading === true ? (
      <div>
        <Spinner />
      </div>
    ) : (
      <Link to={`preview/delivery/print/new`}>
        <div className="preview-wrapper">
          <img src={delivery.printPreviewThumb[this.deliveryStore.previewLanguage]} alt="preview" />
        </div>
        <div className="preview-icon">
          <IconPreview />
        </div>
      </Link>
    );
  };

  render() {
    const {
      isSelected: emailSelected,
      selectionValue: emailSelectionValue,
      max: emailMax,
    } = this.channelSelectionValues.email;
    const {
      isSelected: printSelected,
      selectionValue: printSelectionValue,
      max: printMax,
    } = this.channelSelectionValues.print;

    const emailTemplates = this.template.contentTemplates.filter((t) => t.channel === DeliveryChannelName.Email);
    const printTemplates = this.template.contentTemplates.filter((t) => t.channel === DeliveryChannelName.Print);
    const hasEmailEditableSlots = this.template.contentBlocks.length > 1; // @TODO proper logic
    const hasPrintEditableSlots = this.template.contentBlocks.length > 1; // @TODO proper logic
    const isEmailEditable =
      emailTemplates.length > 1 || this.template.contentFields.length > 0 || hasEmailEditableSlots;
    const isPrintEditable =
      printTemplates.length > 1 || this.template.contentFields.length > 0 || hasPrintEditableSlots;
    const isKRuoka = isKRuokaOnlyChain([this.delivery.chainId]);
    const isB2bDelivery = this.delivery.targetGroup.targetGroupType === ConceptType.B2b;
    const isKRautaOrOnninen = isKRautaChain(this.delivery.chainId) || isOnninenChain(this.delivery.chainId);
    const recipientsLabel = isB2bDelivery ? 'sähköpostia' : 'henkilöä';

    return (
      <div className="box delivery-channels">
        {this.state.hasEmailChannel && (
          <div className="email-channel channel">
            <h3>Sähköposti</h3>
            <div className="box-sections">
              <div className="channel-preview">{this.renderEmailPreview()}</div>
              <div className={`channel-options ${emailSelected ? '' : 'not-selected'}`}>
                {this.maxSelectionCounts ? (
                  <Fragment>
                    <div className="recipients-displays">
                      <div className="number-display">
                        <label>Vastaanottajia</label>
                        <input
                          ref={this.emailSelectionInput}
                          hidden={!this.state.emailSelectionEditable}
                          className="num-input"
                          type="number"
                          defaultValue={`${emailSelectionValue}`}
                          min={0}
                          max={emailMax}
                          onChange={this.handleSelectionChange(DeliveryChannelName.Email)}
                          onBlur={this.blurChannelSelectionEdit}
                        />
                        <span
                          hidden={this.state.emailSelectionEditable}
                          className="num clickable"
                          onClick={this.editChannelSelection(DeliveryChannelName.Email)}
                        >
                          {this.delivery.emailRecipients
                            ? // If email recipients exist (delivery is B2B type)
                              this.groupedB2bEmailRecipients.length -
                              this.delivery.emailRecipients.bannedEmails.length +
                              this.delivery.emailRecipients.additionalEmails.length
                            : isB2bDelivery
                              ? // If delivery is B2B type and no email recipients exist
                                this.groupedB2bEmailRecipients.length
                              : // If delivery is not b2b type, show emailSelectionValue instead
                                emailSelectionValue}
                        </span>
                        <label>{recipientsLabel}</label>
                      </div>
                      {!isKRautaOrOnninen && (
                        <div className="number-display">
                          <label>Hinta-arvio</label>
                          <span className="num">
                            <NumericFormat
                              value={this.getPriceForChannel(DeliveryChannelName.Email)}
                              fixedDecimalScale={true}
                              decimalScale={2}
                              decimalSeparator={','}
                              thousandSeparator={' '}
                              suffix={'€'}
                              displayType="text"
                            />
                          </span>
                        </div>
                      )}
                    </div>
                    {!this.props.disabled && this.state.emailRecipientsEditable && (
                      <div className="recipients-slider">
                        <div className="slider-container">
                          <input
                            className="slider"
                            type="range"
                            min={0}
                            max={emailMax}
                            step="1"
                            autoComplete="off"
                            value={emailSelectionValue}
                            onChange={this.handleSelectionChange(DeliveryChannelName.Email)}
                          />
                        </div>
                      </div>
                    )}
                  </Fragment>
                ) : (
                  <Spinner />
                )}
              </div>
              {!this.props.disabled && (
                <div className="channel-actions">
                  <ToggleButton
                    isSelected={emailSelected}
                    disabled={this.state.isEmailChannelLocked}
                    toggle={this.toggleChannelSelection(DeliveryChannelName.Email)}
                  />
                  {isEmailEditable && (
                    <div className="edit-button-container">
                      <Button color="bordered" link={`content/${DeliveryChannelName.Email}`}>
                        <div>
                          <span>Muokkaa</span>
                          {this.deliveryStore.numberOfValidationErrors !== 0 && (
                            <span title="Puuttuvat täydennykset" className="validation-error">
                              {this.deliveryStore.numberOfValidationErrors}
                            </span>
                          )}
                        </div>
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        {this.state.hasPrintChannel && (
          <div className="print-channel channel">
            <h3>Printti</h3>
            <div className="box-sections">
              <div className="channel-preview">{this.renderPrintPreview()}</div>
              <div className={`channel-options ${printSelected ? '' : 'not-selected'}`}>
                {this.maxSelectionCounts && !this.delivery.maxSelectionCountsLoading ? (
                  <Fragment>
                    <div className="recipients-displays">
                      <div className="number-display">
                        <label>Vastaanottajia</label>
                        <input
                          ref={this.printSelectionInput}
                          hidden={!this.state.printSelectionEditable}
                          className="num-input"
                          type="number"
                          defaultValue={`${printSelectionValue}`}
                          min={0}
                          max={emailMax}
                          onChange={this.handleSelectionChange(DeliveryChannelName.Print)}
                          onBlur={this.blurChannelSelectionEdit}
                        />
                        <span
                          hidden={this.state.printSelectionEditable}
                          className="num clickable"
                          onClick={this.editChannelSelection(DeliveryChannelName.Print)}
                        >
                          {printSelectionValue}
                        </span>
                        <label>taloutta</label>
                      </div>
                      <div className="number-display">
                        <label>Hinta-arvio</label>
                        <span className="num">
                          <NumericFormat
                            value={this.getPriceForChannel(DeliveryChannelName.Print)}
                            fixedDecimalScale={true}
                            decimalScale={2}
                            decimalSeparator={','}
                            thousandSeparator={' '}
                            suffix={'€'}
                            displayType="text"
                          />
                        </span>
                      </div>
                    </div>
                    {!this.props.disabled && (
                      <div className="recipients-slider">
                        <div className="slider-container">
                          <input
                            className="slider"
                            type="range"
                            min={0}
                            max={printMax}
                            step="1"
                            autoComplete="off"
                            value={printSelectionValue}
                            onChange={this.handleSelectionChange(DeliveryChannelName.Print)}
                          />
                        </div>
                      </div>
                    )}
                  </Fragment>
                ) : (
                  <Spinner />
                )}
              </div>
              {!this.props.disabled && (
                <div className="channel-actions">
                  <ToggleButton
                    isSelected={printSelected}
                    disabled={this.state.isPrintChannelLocked}
                    toggle={this.toggleChannelSelection(DeliveryChannelName.Print)}
                  />
                  {isPrintEditable && (
                    <Button color="bordered" link={`content/${DeliveryChannelName.Print}`}>
                      <div>
                        <span>Muokkaa</span>
                        {this.deliveryStore.numberOfValidationErrors !== 0 && (
                          <span title="Puuttuvat täydennykset" className="validation-error">
                            {this.deliveryStore.numberOfValidationErrors}
                          </span>
                        )}
                      </div>
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        {this.delivery.maxSelectionCounts && isKRuoka && (
          <div className="targeted-users">
            <b>
              <span className="targeted-users__value">Kohderyhmän koko:</span> yhteensä
              <span className="targeted-users__value">{printSelectionValue + emailSelectionValue}</span>vastaanottajaa
              (taloutta / henkilöä) , joista
              <span className="targeted-users__value">{this.delivery.maxSelectionCounts.kRuokaApp}</span>henkilöä on
              K-Ruoka-sovelluksen käyttäjiä.
            </b>
          </div>
        )}
      </div>
    );
  }
}

export default ChannelsBox;
