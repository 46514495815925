/* eslint-disable 	@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call, @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return,  @typescript-eslint/no-floating-promises, @typescript-eslint/no-misused-promises, react/no-unescaped-entities */
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import type { ProductOffer } from 'types/next';
import '../delivery/deliveryForm/subforms/offerForm.scss';
import Button from '../../../../common/next/form/button';
import DrawerContent from '../wrappers/drawerContent';
import OfferEditor, { Props as OfferEditorProps } from 'components/common/next/offerEditor';
import { getLink } from '../../routes';
import { OfferEditorMode } from 'components/next/utils';
import UserStore from 'stores/userStore';
import { inject, observer } from 'mobx-react';
import DeliveryStore, { checkDeliveryOffer } from 'stores/next-retailer/deliveryStore';
import _ from 'utils/lodash';
import AlertStore from 'stores/next/alerts';
import { toJS } from 'mobx';

interface Props {
  deliveryStore?: DeliveryStore;
  alertStore?: AlertStore;
  userStore?: UserStore;
  disabled?: boolean;
}
const MobileOfferForm = ({ userStore, deliveryStore, alertStore, disabled }: Props) => {
  const navigate = useNavigate();
  const { mobileOffersId, offerId: offerIdParam } = useParams();
  const [offer, setOffer] = useState<ProductOffer>();
  const [isOfferValid, setIsOfferValid] = useState(false);

  const back = () => {
    navigate(getLink('editMobileOffers', mobileOffersId), { state: { disableNotification: true } });
  };
  const getDelivery = () => {
    // use current delivery
    return deliveryStore.current;
  };
  const getInitialOffer = () => {
    const delivery = getDelivery();
    if (!delivery) {
      return undefined;
    }
    return _.find(delivery.deliveryOffers, { id: offerIdParam });
  };

  const renderHeader = () => {
    if (disabled) {
      return <h2>Tuotenosto</h2>;
    }
    return <h2>Muokkaa tuotenostoa</h2>;
  };

  const renderFooter = () => {
    if (disabled) {
      return null;
    }
    
    return (
      <div className="offer-form-footer">
        <div>&nbsp;</div>
        <div>
          <Button color="bordered" onClick={back}>
            Peruuta
          </Button>
          <Button onClick={save} title={!isOfferValid && `Edun nimi puuttuu tai käytössä on emojeja`} disabled={!isOfferValid}>
            Tallenna
          </Button>
        </div>
      </div>
    );
  };
  const save = async () => {
    const delivery = getDelivery();
    if (!delivery) {
      alertStore.error({
        message: 'Delivery not found',
      });
      return;
    }

    const { deliveryOffers } = delivery;
    const { valid } = await checkDeliveryOffer(offer);

    if (!valid) {
      return;
    }
    if (offerIdParam === 'new') {
      deliveryOffers.push(offer);
    } else {
      const replace = _.findIndex(deliveryOffers, { id: offerIdParam });
      deliveryOffers[replace] = offer;
    }

    alertStore.success({
      message: 'Muutokset hyväksytty',
    });
    back();
  };
  const setOfferValidity = (isOfferValid: boolean) => {
    setIsOfferValid(isOfferValid);
  };
  const onOfferChange = (offer: ProductOffer) => {
    setOffer({ ...offer, activationLandingPage: true });
  };
  const render = () => {
    const initialOffer = getInitialOffer();
    const offerEditorProps: OfferEditorProps = {
      onOfferChange: onOfferChange,
      onValidityChange: setOfferValidity,
      offerOptions: [],
      disableProductOffers: false,
      disableWebstoreOffers: true,
      disableBasketOffers: true,
      requiredRecommendations: undefined,
      allowCustomOffers: true,
      editorMode: OfferEditorMode.EDIT,
      chain: userStore.me.chainId,
      conceptType: undefined,
      forcedItemLimitPer: 'person',
      mobileOffer: true,
      disabled,
    };
    if (offerIdParam !== 'new' && initialOffer) {
      offerEditorProps.initialOffer = toJS(initialOffer) as ProductOffer;
    }
    return (
      <DrawerContent className="offer-form" renderHeader={renderHeader} renderFooter={renderFooter}>
        <OfferEditor {...offerEditorProps} />
      </DrawerContent>
    );
  };
  return render();
};

export default inject('userStore', 'deliveryStore', 'alertStore')(observer(MobileOfferForm));
