/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return,  @typescript-eslint/no-unsafe-argument, 	@typescript-eslint/no-floating-promises */
import React, { useEffect, useState } from 'react';
import _ from 'utils/lodash';
import type { Offer, OfferType, ProductOffer, WebstoreOffer, BasketOffer, OfferOption } from 'types/next';
import { inject, observer } from 'mobx-react';
import DeliveryStore from 'stores/next-retailer/deliveryStore';
import UserStore from 'stores/userStore';
import ContentStore from 'stores/next-retailer/contentStore';
import RadioPill from '../form/radioPill';
import ProductForm from './productForm';
import WebstoreForm from './webstoreForm';
import BasketForm from './basketForm';
import OfferOptionsForm from './optionsForm';
import './offerEditor.scss';
import { checkDeliveryOffer } from 'stores/next-retailer/deliveryStore';
import { toJS } from 'mobx';
import { Role } from 'types/user';
import InfoPopover from 'components/retailer/next/components/common/infoPopover';
import { getLink } from 'components/retailer/next/routes';
import { ReactComponent as ChevronRight } from '@kesko/icons/nav/icon-chevron-right.svg';
import { OfferEditorMode } from 'components/next/utils';
import { doesTextsContainEmojis, isNesteKChain, useDebouncedEffect } from '../../../../utils/helpers';
import { ConceptType } from 'enums/common';

export interface Props {
  initialOffer?: Offer;
  onOfferChange: (offer: Offer) => void;
  onValidityChange?: (isOfferValid: boolean) => void;
  activationLinkOverride?: boolean; // allow overriding value of offer.activationLink
  disableProductOffers?: boolean;
  disableWebstoreOffers?: boolean;
  disableBasketOffers?: boolean;
  allowCustomOffers: boolean;
  offerOptions?: OfferOption[];
  requiredRecommendations?: number;
  editorMode: OfferEditorMode;
  chain?: string;
  conceptType?: ConceptType;
  forcedItemLimitPer?: 'person' | 'household';
  mobileOffer?: boolean;
  disabled?: boolean;
}

interface InjectedProps extends Props {
  userStore?: UserStore;
  contentStore?: ContentStore;
  deliveryStore?: DeliveryStore;
}

export const shouldLockForm = (content, isEditable: boolean) => {
  !isEditable &&
    document
      .querySelectorAll('.offer-form-product input, .offer-form-product select')
      .forEach((select) => ((select as any).disabled = true));

  return !isEditable ? (
    <React.Fragment>
      <h4 style={{ color: 'rgb(255, 105, 0)', marginBottom: '1rem' }}>
        HUOM; tuotetiedot on lukittu tähän lähetykseen.
      </h4>
      <div style={{ pointerEvents: 'none' }}>{content}</div>
    </React.Fragment>
  ) : (
    content
  );
};

// Used when offer is changed inside check to prevent check loop
let checkOfferOnChange = true;

const OfferEditor = ({
  userStore,
  contentStore,
  deliveryStore,
  initialOffer,
  onOfferChange,
  onValidityChange,
  activationLinkOverride,
  disableProductOffers,
  disableWebstoreOffers,
  disableBasketOffers,
  allowCustomOffers,
  offerOptions,
  requiredRecommendations,
  editorMode,
  chain,
  conceptType,
  forcedItemLimitPer,
  mobileOffer,
  disabled,
}: InjectedProps) => {
  const initialOfferType: OfferType = initialOffer ? initialOffer.type : 'product';
  const initialProductOffer = initialOffer && initialOfferType === 'product' ? initialOffer : null;
  const initialWebstoreOffer = initialOffer && initialOfferType === 'webstoreProduct' ? initialOffer : null;
  const initialBasketOffer = initialOffer && initialOfferType === 'basket' ? initialOffer : null;

  const [offerType, setOfferType] = useState<OfferType>(initialOfferType);
  const [productOffer, setProductOffer] = useState<Offer>(initialProductOffer);
  const [webstoreOffer, setWebstoreOffer] = useState<Offer>(initialWebstoreOffer);
  const [basketOffer, setBasketOffer] = useState<Offer>(initialBasketOffer);
  const [optionsTabVisible, setOptionsTabVisible] = useState(false);
  const [recommendedOfferSelected, setRecommendedOfferSelected] = useState(false);

  const getOffer = () => {
    if (offerType === 'product') {
      return productOffer;
    }
    if (offerType === 'webstoreProduct') {
      return webstoreOffer;
    }
    if (offerType === 'basket') {
      return basketOffer;
    }
    return productOffer;
  };

  const getDeliveryOfferIds = (): string[] => {
    return deliveryStore.delivery ? deliveryStore.delivery.deliveryOffers.map((offer) => offer.offerOptionId) : [];
  };

  // Basket discounts are available for every store if not disable
  const allowBasketOffers = () => {
    const { role } = userStore.me;
    if (role === Role.Admin) {
      return true;
    }

    if (disableBasketOffers) {
      return false;
    }

    return true;
  };

  const allowProductOffers = () => {
    const { role } = userStore.me;
    if (role === Role.Admin) {
      return true;
    }

    if (disableProductOffers) {
      return false;
    }

    return true;
  };

  const allowWebstoreOffers = () => {
    const { role } = userStore.me;
    if (role === Role.Admin) {
      return true;
    }

    if (disableWebstoreOffers) {
      return false;
    }

    return true;
  };

  const allowedOfferTypes = () => {
    const allowedOfferTypes = [];
    if (allowProductOffers()) {
      allowedOfferTypes.push('product');
    }
    if (allowWebstoreOffers()) {
      allowedOfferTypes.push('webstoreProduct');
    }
    if (allowBasketOffers()) {
      allowedOfferTypes.push('basket');
    }
    return allowedOfferTypes;
  };

  useEffect(() => {
    if (offerOptions && offerOptions.length > 0) {
      const optionsTabVisible =
        editorMode === OfferEditorMode.VIEW || (editorMode === OfferEditorMode.EDIT && allowCustomOffers)
          ? false
          : true;
      setOptionsTabVisible(optionsTabVisible);

      if (!optionsTabVisible) {
        setOfferType(allowedOfferTypes()[0]);
      }
    }
  }, []);

  const infoButton = (opts: { slug: string; link?: string }) => {
    return (
      <InfoPopover key="info-tab">
        <div
          className="help-content"
          dangerouslySetInnerHTML={{
            __html: contentStore.getContentBySlug(opts.slug, { html: true }),
          }}
        />
        {opts.link && (
          <footer>
            <a href={opts.link} target="_blank" rel="noopener noreferrer">
              Avaa ohjeet uudessa ikkunassa
              <ChevronRight />
            </a>
          </footer>
        )}
      </InfoPopover>
    );
  };

  const validateClientSide = (offer: Offer | null): boolean => {
    if (!offer) return false;
    const validTextWithoutEmojis = doesTextsContainEmojis(offer?.title?.fi, offer?.title?.sv, offer?.additionalRestrictions)
    return !validTextWithoutEmojis;
  };

  const validateBackend = async (offer: Offer): Promise<boolean> => {
    try {
      const jsOffer = toJS(offer);
      const result = await checkDeliveryOffer(offer);
  
      if (result && !_.isEmpty(result.overrides) && _.isEqual(jsOffer, offer)) {

        setOffer({
          offer: { ...offer, ...(result.overrides as Offer) },
          preserveOptionId: true,
          check: false,
        });
      }
  
      return result && result.valid && !_.isEmpty(result.tosOffer.image);
    } catch {
      return false;
    }
  };

  const checkOffer = async () => {
    const offer = getOffer();
    
    const isClientValid = validateClientSide(offer);
    if (onValidityChange) {
      onValidityChange(isClientValid);
    }
    
    if (!isClientValid || !offer) return;
  
    const isBackendValid = await validateBackend(offer);
    if (onValidityChange) {
      onValidityChange(isBackendValid);
    }
  };
  
  useDebouncedEffect(
    () => {
      if (checkOfferOnChange) {
        checkOffer();
      }
    },
    [productOffer, webstoreOffer, basketOffer],
    500,
  );

  const switchTab = (tab: string) => () => {
    switch (tab) {
      case 'product':
        setOfferType('product');
        setOptionsTabVisible(false);
        break;
      case 'basket':
        setOfferType('basket');
        setOptionsTabVisible(false);
        break;
      case 'webstore':
        setOfferType('webstoreProduct');
        setOptionsTabVisible(false);
        break;
      case 'options':
        setOptionsTabVisible((prevOptionsTabVisible) => !prevOptionsTabVisible);
        break;
    }
  };

  const setOffer = ({
    offer,
    isValid,
    preserveOptionId = false,
    check = true,
  }: {
    offer: Offer;
    check?: boolean;
    isValid?: boolean;
    preserveOptionId?: boolean;
  }) => {
    checkOfferOnChange = check;
    onOfferChange(offer);

    if (isValid !== undefined && onValidityChange) {
      onValidityChange(isValid);
    }

    if (!preserveOptionId) {
      // by default, mark this offer as custom once an edit has been made
      offer.offerOptionId = null;
    }

    if (_.find(offerOptions, { id: offer.offerOptionId })) {
      setRecommendedOfferSelected(true);
    } else {
      setRecommendedOfferSelected(false);
    }

    if (offer.type === 'product') {
      setProductOffer({ ...offer });
    }
    if (offer.type === 'webstoreProduct') {
      setWebstoreOffer({ ...offer });
    }
    if (offer.type === 'basket') {
      setBasketOffer({ ...offer });
    }
  };

  const render = () => {
    const tabs: JSX.Element[] = [];
    // custom offers tabs
    if (allowCustomOffers) {
      if (editorMode === OfferEditorMode.EDIT || !allowCustomOffers) {
        if (allowProductOffers()) {
          tabs.push(
            <RadioPill
              key="product-tab"
              name="tab"
              label="Tuote/Tuoteryhmä"
              value="product"
              onChange={switchTab('product')}
              checked={!optionsTabVisible && offerType === 'product'}
            />,
          );
        }

        if (allowWebstoreOffers() && !isNesteKChain(chain)) {
          tabs.push(
            <RadioPill
              key="webstoreProduct-tab"
              name="tab"
              label="Verkkokauppa"
              value="webstoreProduct"
              onChange={switchTab('webstore')}
              checked={!optionsTabVisible && offerType === 'webstoreProduct'}
            />,
          );
        }

        // basket offers tab
        if (allowBasketOffers() && editorMode === OfferEditorMode.EDIT) {
          tabs.push(
            <RadioPill
              key="basket-tab"
              name="tab"
              label="Ostoskorialennus"
              value="basket"
              onChange={switchTab('basket')}
              checked={!optionsTabVisible && offerType === 'basket'}
            />,
          );
        }
      }
    }

    // offer options tab
    if (offerOptions && offerOptions.length > 0) {
      tabs.push(
        <RadioPill
          key="options-tab"
          name="tab"
          label="Suositukset"
          value="options"
          onChange={switchTab('options')}
          checked={optionsTabVisible}
        />,
      );
    }

    if (userStore.me.role === Role.Retailer) {
      const slug = mobileOffer ? `info-mobile-offers-${offerType}` : `info-${offerType}`;
      tabs.push(infoButton({ slug, link: getLink('help', 'etu-vaihtoehdot') }));
    }
    
    const offer = getOffer();
    return (
      <div className="offer-editor">
        <div className="offer-types">{tabs.length > 1 ? tabs : null}</div>
        {optionsTabVisible ? (
          <OfferOptionsForm
            offer={offer}
            setOffer={setOffer}
            offerOptions={offerOptions}
            maybeEditOfferOption={() => {
              if (editorMode === OfferEditorMode.EDIT) {
                setOptionsTabVisible(false);
              }
            }}
            selectedOfferIds={getDeliveryOfferIds()}
            requiredRecommendations={requiredRecommendations}
            editorMode={editorMode}
          />
        ) : (
          <React.Fragment>
            {offerType === 'product' && (
              <ProductForm
                offer={offer as ProductOffer}
                setOffer={setOffer}
                isEditable={
                  disabled === true ? false : editorMode === OfferEditorMode.EDIT && !recommendedOfferSelected
                }
                forcedItemLimitPer={forcedItemLimitPer}
                mobileOffer={mobileOffer}
                disabled={disabled}
              />
            )}
            {offerType === 'webstoreProduct' && (
              <WebstoreForm
                offer={offer as WebstoreOffer}
                setOffer={setOffer}
                isEditable={editorMode === OfferEditorMode.EDIT && !recommendedOfferSelected}
              />
            )}
            {offerType === 'basket' && (
              <BasketForm
                offer={offer as BasketOffer}
                setOffer={setOffer}
                activationLinkOverride={activationLinkOverride}
                isRetailer={userStore.me.role === Role.Retailer}
                isEditable={editorMode === OfferEditorMode.EDIT && !recommendedOfferSelected}
                conceptType={conceptType}
              />
            )}
          </React.Fragment>
        )}
        <pre className="debug">{JSON.stringify(offer, null, 2)}</pre>
      </div>
    );
  };

  return render();
};

export default inject('userStore', 'contentStore', 'deliveryStore')(observer(OfferEditor));
