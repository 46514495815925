/* eslint-disable @typescript-eslint/no-floating-promises, @typescript-eslint/no-unnecessary-type-assertion */
import DeliveryStore from 'stores/next-retailer/deliveryStore';
import '../delivery/deliveryView.scss';
import ResultsStore, { CombinedOfferResult } from 'stores/next-retailer/resultsStore';
import { inject, observer } from 'mobx-react';
import { Link, useParams } from 'react-router-dom';
import React, { Fragment, useEffect } from 'react';
import _ from 'utils/lodash';
import Spinner from 'components/common/next/spinner';
import { Chain, ConceptType } from 'enums/common';
import * as date from 'date-fns';
import * as dateFns from 'date-fns';
import { castDate, imageSrc, calculateDivisionSafe } from 'utils/helpers';
import DeliveryTarget from '../delivery/deliveryTarget';
import { DeliveryOffer } from 'types/next';
import { NumericFormat } from 'react-number-format';

interface InjectedProps {
  deliveryStore?: DeliveryStore;
  resultsStore?: ResultsStore;
  isKCM?: boolean;
}
const MobileOffersDeliveryView = ({ deliveryStore, resultsStore, isKCM }: InjectedProps) => {
  const { mobileOfferId: mobileOfferIdParam } = useParams();

  useEffect(() => {
    const init = async () => {
      const delivery = await deliveryStore.getDelivery(mobileOfferIdParam, false);
      deliveryStore.setCurrent(delivery);
    };
    init();
  }, []);

  const getDelivery = () => {
    return _.find(deliveryStore.deliveries, { id: mobileOfferIdParam });
  };
  const isLoading = getDelivery()?.selectionCountsLoading || !getDelivery()?.selectionCounts;
  const getResults = () => {
    const delivery = getDelivery();
    if (delivery) {
      return resultsStore.getDeliveryResults(delivery.id);
    }
    return null;
  };
  const isReady = () => {
    return Boolean(getDelivery());
  };
  const renderDeliveryHeader = () => {
    const delivery = getDelivery();
    const title = delivery.description;
    return (
      <div className={`delivery-header box-nega ${Chain.kRuoka}`}>
        <div className="delivery-info">
          <h2>{title}</h2>
          <ul>
            <li>
              Asiakkailla: <strong>{date.format(castDate(castDate(delivery.startDate)), 'd.M.yyyy')}</strong>
            </li>
            <li>
              Kampanja-aika:{' '}
              <strong>
                {date.format(castDate(delivery.offerStartDate), 'd.M.')}–
                {date.format(castDate(castDate(delivery.offerEndDate)), 'd.M.yyyy')}
              </strong>
            </li>
            <li>
              Lähetyksen tulokset päivittyvät K Markkinointiin muutaman päivän viiveellä. Huom: aktivoitavien
              mobiilietujen osalta "Plussa-taloutta lähestytty" -luku päivittyy vasta, kun tarjouksella on lunastuksia.
              Siihen asti luku on "0", vaikka etu on onnistuneesti ladattu asiakkaiden Plussa-korteille.{' '}
            </li>
          </ul>
        </div>
      </div>
    );
  };
  // TODO: Implement results here
  const renderDeliveryMobileChannel = () => {
    const delivery = getDelivery();
    const results = { mobileDeliveryResultsAvailable: false };

    return (
      <div className="box channel-box channel-email">
        <h3 className="channel-title">
          Mobiili:{' '}
          {delivery.selectionCounts && (
            <Fragment>
              toteutuneita vastaanottajia{' '}
              <span className="channel-count">{delivery.selectionCounts.channels.mobile}</span> henkilöä
              {dateFns.isFuture(new Date(delivery.startDate)) && ' (Ei vielä lähetetty)'}
            </Fragment>
          )}
        </h3>
        <h4 className="channel-subtitle">
          {delivery.deliveryIdentifiers.filter((identifier) => identifier.includes('_mobile_')).join(', ')}
        </h4>
        <div className="box-sections">
          {results && results.mobileDeliveryResultsAvailable ? (
            <Fragment>
              <div>Create the results here</div>
            </Fragment>
          ) : (
            <div className="box-section">
              <div className="result no-results">Ei tuloksia saatavilla.</div>
            </div>
          )}
        </div>
      </div>
    );
  };
  const renderPriceInformation = (offer: DeliveryOffer) => {
    if (
      offer.productOfferType === 'price' ||
      offer.productOfferType === 'percentage' ||
      offer.productOfferType === 'gratis'
    ) {
      return (
        <div className="box-section">
          <div className="result offer-results">
            <h4>Hintatiedot</h4>
            <Fragment>
              <div className="number-display">
                <NumericFormat
                  value={Math.abs(
                    offer.productOfferType === 'percentage' ? offer.discountPercentage : offer.discountPrice || 0,
                  )}
                  displayType="text"
                  suffix={offer.productOfferType === 'percentage' ? '%' : '€'}
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale={offer.productOfferType === 'price'}
                />
              </div>
              <p>
                {offer.productOfferType === 'price'
                  ? 'alennettu hinta'
                  : offer.productOfferType === 'percentage'
                    ? 'alennusprosentti'
                    : 'veloituksetta'}
              </p>
              {offer.regularPriceMin !== offer.regularPriceMax ? (
                <Fragment>
                  <div className="number-display" style={{ color: 'grey' }}>
                    <NumericFormat
                      value={Math.abs(offer.regularPriceMin)}
                      displayType="text"
                      suffix="€"
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </div>
                  <p>Normaali alin hinta</p>
                  <div className="number-display" style={{ color: 'grey' }}>
                    <NumericFormat
                      value={Math.abs(offer.regularPriceMax)}
                      displayType="text"
                      suffix="€"
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </div>
                  <p>Normaali korkein hinta</p>
                </Fragment>
              ) : (
                <Fragment>
                  <div className="number-display" style={{ color: 'grey' }}>
                    <NumericFormat
                      value={Math.abs(offer.regularPriceMax)}
                      displayType="text"
                      suffix="€"
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  </div>
                  <p>Normaali hinta</p>
                </Fragment>
              )}
            </Fragment>
          </div>
        </div>
      );
    }
    return null;
  };
  const renderDeliveryOffer = (offer: DeliveryOffer) => {
    const results = getResults();
    const offerResults = results && (_.get(results, ['combinedOfferResults', offer.offerId]) as CombinedOfferResult);
    const pull = offerResults
      ? calculateDivisionSafe(offerResults.householdPurchased, offerResults.targetedHouseholds)
      : null;
    return (
      <div key={offer.id} className="box offer-box">
        <h3 className="offer-title">{offer.title.fi}</h3>
        {offer.products &&
          offer.products.map((product) =>
            offer.products !== null && product.ean !== undefined ? (
              <h4 className="offer-eancode" key={product.ean}>
                EAN-koodi: {product.ean}, norm. hinta:{' '}
                {
                  <NumericFormat
                    value={Math.abs(product.price)}
                    displayType="text"
                    suffix="€"
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                  />
                }
              </h4>
            ) : null,
          )}
        <Link to={`offer/${offer.id}`}>
          <h4 className="offer-subtitle-link">{offer.offerId}</h4>
        </Link>
        <div className="box-sections">
          <div className="box-section">
            <div className="offer-image">
              <img src={imageSrc(offer.image, { fm: 'png', w: 400 })} alt="offer" />
            </div>
          </div>
          {offer.type === 'product' && renderPriceInformation(offer)}
          {offerResults ? (
            <Fragment>
              <div className="box-section">
                <div className="result offer-results">
                  <h4>Etujen lunastukset</h4>
                  <div className="number-display">
                    <NumericFormat value={offerResults.totalRedeemers} displayType="text" decimalScale={0} />
                  </div>
                  <p>lunastettua etua kampanja-aikana</p>
                  <div className={isKCM ? 'number-display hidden' : 'number-display'}>
                    <NumericFormat
                      value={Math.abs(offerResults.totalOfferValue)}
                      displayType="text"
                      suffix="€"
                      decimalSeparator=","
                      decimalScale={2}
                    />
                  </div>
                  <p className={isKCM ? 'hidden' : ''}>asiakkaille annettujen alennusten yhteenlaskettu arvo</p>
                </div>
              </div>
              <div className="box-section">
                <div className="result offer-results">
                  <h4>Pull</h4>
                  <div className="number-display">
                    {_.isFinite(pull) ? (
                      <NumericFormat
                        value={100 * pull}
                        displayType="text"
                        decimalScale={1}
                        decimalSeparator=","
                        suffix="%"
                      />
                    ) : (
                      '-'
                    )}
                  </div>
                  <p>talouksista lunasti edun</p>
                </div>
              </div>
            </Fragment>
          ) : (
            <div className="box-section">
              <div className="result no-results">Ei lunastettuja tarjouksia.</div>
            </div>
          )}
        </div>
      </div>
    );
  };
  const counts = (() => {
    // const results = getResults();
    const delivery = getDelivery();
    if (!isLoading) {
      // if (results && results.mobileDeliveryResultsAvailable) {
      //   return results.mobileResults.totalRecipients;
      // }
      return delivery.selectionCounts.channels.mobile;
    }
    return 0;
  })();
  const renderDeliveryResults = () => {
    const results = getResults();
    if (!results) {
      return null;
    }
    return (
      <div className="results box">
        <div className="box-section">
          <div className="big-number-display">
            <div className="big-number">
              <NumericFormat value={counts} displayType="text" thousandSeparator="&nbsp;" />
            </div>
            <label>Arvioitu vastaanottajamäärä</label>
          </div>
        </div>
        {!_.isUndefined(results.targetedHouseholdsTotal) && (
          <div className="box-section">
            <div className="big-number-display">
              <div className="big-number">
                <NumericFormat value={results.targetedHouseholdsTotal} displayType="text" thousandSeparator="&nbsp;" />
              </div>
              <label>Plussa-taloutta lähestytty</label>
            </div>
          </div>
        )}
        {!_.isUndefined(results.householdPurchasedTotal) && (
          <div className="box-section">
            <div className="big-number-display">
              <div className="big-number">
                <NumericFormat value={results.householdPurchasedTotal} displayType="text" thousandSeparator="&nbsp;" />
              </div>
              <label>edun lunastanutta taloutta</label>
            </div>
          </div>
        )}
        {/* GT header box3 */}
        <div className="box-section">
          <div className="big-number-display">
            <div className="big-number">
              {_.isFinite(results.pullPercentage) ? (
                <NumericFormat
                  value={results.pullPercentage}
                  displayType="text"
                  suffix="%"
                  decimalScale={1}
                  decimalSeparator=","
                />
              ) : (
                '-'
              )}
            </div>
            <label>talouksista lunasti vähintään yhden edun.</label>
          </div>
        </div>
      </div>
    );
  };
  const render = () => {
    if (!isReady()) {
      return <Spinner />;
    }
    const delivery = getDelivery();
    return (
      <div className="delivery-view">
        <div className="main-content">
          <div className="content">
            {renderDeliveryHeader()}

            {renderDeliveryResults()}

            <h2>Lähetyksen kohdennus</h2>
            <DeliveryTarget delivery={delivery} conceptType={ConceptType.Season} />
            <div className="section-header">
              <h2>Kanavat</h2>
              {renderDeliveryMobileChannel()}
            </div>

            {delivery.deliveryOffers && delivery.deliveryOffers.length > 0 && (
              <Fragment>
                <h2>Edut ja tuotenostot</h2>
                {delivery.deliveryOffers.map(renderDeliveryOffer)}
              </Fragment>
            )}

            <pre className="debug">{JSON.stringify(delivery, null, 2)}</pre>
          </div>
        </div>
      </div>
    );
  };
  return render();
};

export default inject('deliveryStore', 'resultsStore')(observer(MobileOffersDeliveryView));
